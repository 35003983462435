import React from 'react';
import { Grid, Form, Button, Modal, Icon, Image } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import logo from '../../../images/vintes-logo.png';
import './SecondStep.css';

const passwordMinLength = min => value =>
	value && value.length < min ? `La password deve avere ${min} o più caratteri` : undefined;
const passwordMinLength6 = passwordMinLength(6)

const passwordField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				type="password"
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				type="password"
			/>
		)
}

function SecondStep(props) {

	var userLoginPath = '/';

	const { handleSubmit, invalid, submitting, pristine } = props;
	return (
		<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' className='login'>
			<Grid.Column textAlign='left' style={{ maxWidth: 450 }}>

				<div className='secondStep header'>
					<Image src={logo} centered />
				</div>

				<div className='secondStep header'>
					Inserisci e conferma la nuova password; poi clicca sul pulsante <strong>Salva</strong>.<br />
				</div>

				<Form name='secondStep' onSubmit={handleSubmit}>
					<label className='secondStep form-label'>Nuova Password *</label>
					<Field name="password" component={passwordField} validate={[passwordMinLength6]} type="password" fluid />
					<label className='secondStep form-label'>Conferma Password *</label>
					<Field name="confirmPassword" component={passwordField} validate={[passwordMinLength6]} type="password" fluid />
					<Button type='submit' className='secondStep button' disabled={invalid || submitting || pristine}>Salva</Button>
				</Form>

				{props.secondStep.error != '' &&
					<div className="ui red message">{props.secondStep.error}</div>
				}

			</Grid.Column>
			<Modal size='tiny' open={props.secondStep.completed}>
				<div className='modal-header'>Messaggio informativo</div>
				<div className='modal-content'>
					<p>{props.secondStep.message} <Icon name='check' /></p>
				</div>
				<div className='modal-footer'>
					<Button onClick={(event) => props.pushTo(event, userLoginPath)}>OK</Button>
				</div>
			</Modal>
		</Grid>
	);
}

SecondStep = reduxForm({
	form: 'secondStep'
})(SecondStep)

export default SecondStep
