import backendService from '../../../../services/BackendService';
import configApp from '../../../../config/config.app';

export const createFinalUser = (formData) => dispatch => {
	dispatch({ type: 'finalUser.create.setLoading' });
	formData.roles = [configApp.FINAL_USER];
	var filter = {
		"where": {
			"prefix": formData.prefix,
			"mobile": formData.mobile
		}
	}
	return backendService.getFinalUserList(filter)
		.then(async res => {
			if (res.length == 0)
				backendService.createFinalUser(formData)
					.then(async res => {
						dispatch({ type: 'finalUser.create.createFinalUser' });
					})
					.catch(err => {
						dispatch({ type: 'finalUser.create.error', payload: err });
					});
			else {
				var message = 'Attenzione! Numero di cellulare già presente nel sistema. Creazione non riuscita.';
				dispatch({ type: 'finalUser.create.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'finalUser.create.error', payload: err });
		});
}

export const unmountCreate = () => dispatch => {
	dispatch({ type: 'finalUser.create.unmountCreate' });
}
