import backendService from '../../../../services/BackendService';

export const loadFinalUserData = (id) => dispatch => {
	dispatch({ type: 'finalUser.edit.setLoading' });
	return backendService.getFinalUser(id)
		.then(async res => {
			dispatch({ type: 'finalUser.edit.loadFinalUserData', payload: res });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.edit.error', payload: err });
		});
}

export const editFinalUser = (id, formData) => dispatch => {
	dispatch({ type: 'finalUser.edit.setLoading' });

	return backendService.editFinalUser(id, formData)
		.then(async res => {
			dispatch({ type: 'finalUser.edit.editFinalUser', payload: res });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.edit.error', payload: err });
		});
}

export const unmountEdit = () => dispatch => {
	dispatch({ type: 'finalUser.edit.unmountEdit' });
}
