import backendService from '../../services/BackendService';
import { push } from 'connected-react-router';

export const authenticate = (userDataObj) => dispatch => {
	return backendService.login(userDataObj)
		.then(res => {
			if (!res.error) {
				if (res.response.isLoggedIn) {
					dispatch({ type: 'authentication.login', payload: true });
					/*if (res.response.isAdmin) {
						dispatch(push('/admin/home'));
					}
					else {*/
					dispatch(push('/user/home'));
					//}
				}
			}
			else {
				dispatch({ type: 'authentication.error', payload: res.error.message });
			}
		})
	/*.catch(err => {
		dispatch({ type: 'authentication.error', payload: err });
	});*/
}
