const commonState = {
	mailError: '',
	error: ''
};
  
export function common(state = commonState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'common.mail.sendMail': {	
			return {
				...state
			};
		}

		case 'common.mail.error': {	
			return {
				...state,
				mailError: payload
			};
		}

		case 'common.error': {	
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}