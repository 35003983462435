import React from 'react';
import { Container, Table, Image, Button, Modal, Icon } from 'semantic-ui-react';
import details from '../../../../images/details.png';
import edit from '../../../../images/edit.png';
import edit_off from '../../../../images/edit_off.png';
import del from '../../../../images/delete.png';
import del_off from '../../../../images/delete_off.png';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';

export default function UnitList(props) {


	var finalUserUnitRefCreatePath = '/user/unitaProduttiva/crea/';
	var finalUserUnitRefEditPath = '/user/unitaProduttiva/modifica/';
	var finalUserUnitRefDetailsPath = '/user/unitaProduttiva/dettagli/';

	return (
		<div>
			<FlexContainer className='row space-between center sub-title'>
				<div>Unità produttive</div>
				<div>
					<a href='' onClick={(event) => props.pushTo(event, finalUserUnitRefCreatePath + props.id)} title="Crea una unità produttiva dell'azienda">
						<Container fluid className='link-button small'>
							&nbsp;Crea&nbsp;
						</Container>
					</a>
				</div>
			</FlexContainer>
			{props.details.data.hasOwnProperty("units") &&
				<Table celled className='table small'>
					<Table.Header className='table-header'>
						<Table.Row>
							<Table.HeaderCell textAlign='center'>Nome</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Descrizione</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Azioni</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{[...props.details.data.units].map((x, i) =>
							<Table.Row key={x.id}>
								<Table.Cell textAlign='center'>{x.name}</Table.Cell>
								<Table.Cell textAlign='center'>{x.description}</Table.Cell>
								<Table.Cell textAlign='center'>
									<FlexContainer className='row center'>
										<a href='' onClick={(event) => props.pushTo(event, finalUserUnitRefDetailsPath + x.id)} title='Visualizza dettagli unità produttiva'>
											<Container fluid className='link-box small'>
												<Image src={details} className='w25' />
											</Container>
										</a>
										<div className='p5'></div>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
											<a href='' onClick={(event) => props.pushTo(event, finalUserUnitRefEditPath + x.id)} title='Modifica unità produttiva'>
												<Container fluid className='link-box small'>
													<Image src={edit} className='w25' />
												</Container>
											</a>
										}
										{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
											<div fluid className='link-box small-off'>
												<Image src={edit_off} className='w25' />
											</div>
										}
										<div className='p5'></div>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) && x.isDeletable &&
											<a href='' onClick={(event) => props.openUnitDeletePopup(event, x.id, x.name)} title='Elimina'>
												<Container fluid className='link-box small'>
													<Image src={del} className='w25' />
												</Container>
											</a>
										}
										{(!props.cookieSession.isAdmin && !props.cookieSession.isOperator) || !x.isDeletable &&
											<div fluid className='link-box small-off'>
												<Image src={del_off} className='w25' />
											</div>
										}
									</FlexContainer>
								</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>

					<Table.Footer>

					</Table.Footer>
				</Table>
			}
			{!props.details.data.hasOwnProperty("units") &&
				<Container fluid className='info-box'>Non ci sono ancora unità produttive collegate a questa azienda</Container>
			}

			<Modal size='tiny' open={props.details.unitDeletePopup}>
				<div className='modal-header'>Messaggio di conferma</div>
				<div className='modal-content'>
					<p>Si è sicuri di voler eliminare l'unità produttiva <u>{props.details.unitDeletePopupName}</u>?</p>
				</div>
				<div className='modal-footer'>
					<Button onClick={props.closeUnitDeletePopup}><Icon name='remove' />NO</Button>
					<Button onClick={() => props.deleteUnit(props.details.unitDeletePopupId, props.details.data.id)}><Icon name='checkmark' />SI</Button>
				</div>
			</Modal>
		</div>
	);



}
