import React from 'react';
import { Container, Grid, Image, Dimmer, Loader } from 'semantic-ui-react';
import edit from '../../../../images/edit.png';
import './Details.css';

export default function Details(props) {

	var operatorEditPath = '/user/operatore/modifica/';

	return (
		<Container className='operatorDetails'>
			<div className='title-box  pb10'>Dettagli Operatore
			{props.cookieSession.isAdmin &&
					<a href='' className='float-right' onClick={(event) => props.pushTo(event, operatorEditPath + props.details.data.id)} title='Modifica'>
						<div className='link-box small'>
							<Image src={edit} size='mini' />
						</div>
					</a>
				}
			</div>

			{props.details.isLoading &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			}

			{!props.details.isLoading &&
				<div>
					<Grid columns={2} divided stackable>
						<Grid.Row>
							<Grid.Column>
								Nome: <span className='fieldValue'>{props.details.data.firstName}</span>
							</Grid.Column>
							<Grid.Column>
								Cognome: <span className='fieldValue'>{props.details.data.lastName}</span>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								Username: <span className='fieldValue'>{props.details.data.username}</span>
							</Grid.Column>
							<Grid.Column>
								Email: <span className='fieldValue'>{props.details.data.email}</span>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								Ruoli: <span className='fieldValue'>{props.details.data.roles}</span>
							</Grid.Column>
							<Grid.Column>

							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			}
		</Container>
	);
}
