import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Create from './Create';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form'
import { createMap, getUnitList, unmountCreate } from '../../../../states/user/map/create/create-actions';
import { pushTo } from '../../../../states/common/common-actions';
import Utils from '../../../../utils/Utils';

function CreateContainer(props) {

	var cookieSession = Utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		create: props.create,
		cookieSession: cookieSession,
		onSubmit: props.createMap,
		pushTo: props.pushTo,
		initialValues: {
			unitId: props.match.params.unitId,
			processingId: props.match.params.processingId
		},
		formUnitId: props.formUnitId
	}

	useEffect(() => {
		props.getUnitList();
		return () => props.unmountCreate()
	}, []);

	return (
		<MainLayout {...props}>
			<Create {...localProps} />
		</MainLayout>
	);
}

const selector = formValueSelector('createMap')
export default connect(
	state => ({
		create: state.mapCreate,
		formUnitId: selector(state, 'unitId')
	}),
	{ createMap, getUnitList, unmountCreate, pushTo }
)(CreateContainer);
