const mapEditState = {
	data: {},
	mapEdited: false,
	unitList: [],
	unitListLoaded: false,
	isLoading: false,
	error: '',
	unitListError: ''
};

export function mapEdit(state = mapEditState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'map.edit.loadData': {
			return {
				...state,
				data: payload,
				isLoading: false,
				error: ''
			};
		}

		case 'map.edit.editMap': {
			return {
				...state,
				mapEdited: true,
				isLoading: false,
				error: ''
			};
		}

		case 'map.edit.getUnitList': {
			return {
				...state,
				unitList: payload,
				unitListLoaded: true
			};
		}

		case 'map.edit.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'map.edit.unitList.setLoading': {
			return {
				...state,
				unitListLoaded: false
			};
		}

		case 'map.edit.unmountEdit': {
			return {
				...state,
				mapEdited: false
			};
		}

		case 'map.edit.error': {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		}

		case 'map.edit.unitList.error': {
			return {
				...state,
				unitListError: payload
			};
		}

		default:
			return state;
	}
}
