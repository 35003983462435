import axios from 'axios';
import config from '../config/config.api';
import qs from 'qs';
import utils from '../utils/Utils';

/* BEGIN AUTHENTICATION CALLS */
export async function login(userDataObj) {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.SSR_SERVER}/login`,
		data: userDataObj,
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			callResult = err.response.data
		});

	return callResult;
}

export async function logout() {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.SSR_SERVER}/logout`
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END AUTHENTICATION CALLS */

/* BEGIN PASSWORD RECOVER CALLS */
export async function recover(userDataObj) {
	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.BACKEND_API_SERVER}/users/reset-password/init`,
		data: userDataObj,
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			callResult = err.response.data
		});

	return callResult;
}

export async function resetPassword(userDataObj) {
	var callResult = {};
	await axios({
		method: 'put',
		url: `${config.BACKEND_API_SERVER}/users/reset-password/finish`,
		data: userDataObj,
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			callResult = err.response.data
		});

	return callResult;
}
/* END PASSWORD RECOVER CALLS */

/* BEGIN OPERATOR CALLS */
export async function createOperator(data) {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.BACKEND_API_SERVER}/users`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function editOperator(id, data) {

	var callResult = {};
	await axios({
		method: 'put',
		url: `${config.BACKEND_API_SERVER}/users/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getOperator(id) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/users/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getOperatorList(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/users`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function countOperator(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/users/count`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function deleteOperator(id) {

	var callResult = {};
	await axios({
		method: 'delete',
		url: `${config.BACKEND_API_SERVER}/users/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err.message)
		});

	return callResult;
}
/* END OPERATOR CALLS */

/* BEGIN FINAL USER CALLS */
export async function createFinalUser(data) {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.BACKEND_API_SERVER}/final-users`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function editFinalUser(id, data) {

	var callResult = {};
	await axios({
		method: 'put',
		url: `${config.BACKEND_API_SERVER}/final-users/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getFinalUser(id, filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getFinalUserList(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function countFinalUser(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/count`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function deleteFinalUser(id) {

	var callResult = {};
	await axios({
		method: 'delete',
		url: `${config.BACKEND_API_SERVER}/final-users/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err.message)
		});

	return callResult;
}

export async function isFinalUserDeletable(id) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/isDeletable/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END FINAL USER CALL */

/* BEGIN FINAL USER - UNIT CALL */
export async function getFinalUserUnitList(id) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/${id}/units`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END FINAL USER - UNIT CALL */

/* BEGIN FINAL USER - EMPLOYEE CALL */
export async function getFinalUserEmployeeList(id) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/${id}/units`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function deleteEmployee(id) {

	var callResult = {};
	await axios({
		method: 'delete',
		url: `${config.BACKEND_API_SERVER}/employees/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err.message)
		});

	return callResult;
}
/* END FINAL USER - EMPLOYEE CALL */

/* BEGIN UNIT CALLS */
export async function createUnit(data) {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.BACKEND_API_SERVER}/units`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function editUnit(id, data) {

	var callResult = {};
	await axios({
		method: 'put',
		url: `${config.BACKEND_API_SERVER}/units/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getUnit(id, filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/units/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getUnitList(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/units`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function countUnit(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/units/count`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function deleteUnit(id) {

	var callResult = {};
	await axios({
		method: 'delete',
		url: `${config.BACKEND_API_SERVER}/units/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err.message)
		});

	return callResult;
}

export async function isUnitDeletable(id) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/units/isDeletable/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END UNIT CALLS */

/* BEGIN UNIT - PROCESSING CALL */
export async function getUnitProcessingList(userId, unitId) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/${userId}/units/${unitId}/processings`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END UNIT - PROCESSING CALL */

/* BEGIN PROCESSING CALLS */
export async function createProcessing(data) {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.BACKEND_API_SERVER}/processings`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function editProcessing(id, data) {

	var callResult = {};
	await axios({
		method: 'put',
		url: `${config.BACKEND_API_SERVER}/processings/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getProcessing(id, filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/processings/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getProcessingList(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/processings`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function countProcessing(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/processings/count`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function deleteProcessing(id) {

	var callResult = {};
	await axios({
		method: 'delete',
		url: `${config.BACKEND_API_SERVER}/processings/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err.message)
		});

	return callResult;
}

export async function isProcessingDeletable(id) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/processings/isDeletable/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END PROCESSING CALL */

/* BEGIN PROCESSING - MAP CALL */
export async function getProcessingMapList(userId, processingId) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/final-users/${userId}/processings/${processingId}/maps`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}
/* END PROCESSING - MAP CALL */

/* BEGIN MAP CALLS */
export async function createMap(params, data) {

	var callResult = {};
	await axios({
		method: 'post',
		url: `${config.BACKEND_API_SERVER}/maps`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`,
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data'
		},
		params: params,
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function editMap(id, params, data) {

	var callResult = {};
	await axios({
		method: 'patch',
		url: `${config.BACKEND_API_SERVER}/maps/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`,
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data'
		},
		params: params,
		data: data
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getMap(id, filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/maps/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function getMapList(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/maps`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function countMap(filter) {

	var callResult = {};
	await axios({
		method: 'get',
		url: `${config.BACKEND_API_SERVER}/maps/count`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		},
		params: {
			filter: filter
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err)
		});

	return callResult;
}

export async function deleteMap(id) {

	var callResult = {};
	await axios({
		method: 'delete',
		url: `${config.BACKEND_API_SERVER}/maps/${id}`,
		headers: {
			authorization: `Bearer ${utils.getCookieSession().token}`
		}
	})
		.then(function (res) {
			callResult = res.data
		})
		.catch(function (err) {
			throw new Error(err.message)
		});

	return callResult;
}
/* END MAP CALLS */

export default {
	login,
	logout,
	recover,
	resetPassword,
	createOperator,
	editOperator,
	getOperator,
	getOperatorList,
	countOperator,
	deleteOperator,
	createFinalUser,
	editFinalUser,
	getFinalUser,
	getFinalUserList,
	getFinalUserUnitList,
	countFinalUser,
	deleteFinalUser,
	getFinalUserEmployeeList,
	deleteEmployee,
	isFinalUserDeletable,
	createUnit,
	editUnit,
	getUnit,
	getUnitList,
	getUnitProcessingList,
	countUnit,
	deleteUnit,
	isUnitDeletable,
	createProcessing,
	editProcessing,
	getProcessing,
	getProcessingList,
	getProcessingMapList,
	countProcessing,
	deleteProcessing,
	isProcessingDeletable,
	createMap,
	editMap,
	getMap,
	getMapList,
	countMap,
	deleteMap
}
