const topMenuState = {
	error: ''
  };
  
  export function topMenu(state = topMenuState, action) {
	let payload = action.payload;
  
	switch (action.type) {
  
	  case 'topMenu.error': {	
		  return {
			...state,
			error: payload
		  };
	  }
  
	  default:
		return state;
	}
  }