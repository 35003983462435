import backendService from '../../../../services/BackendService';
import config from '../../../../config/config.app';

export const countMap = () => dispatch => {
	dispatch({ type: 'map.list.setLoading' });
	return backendService.countMap()
		.then(res => {
			dispatch({ type: 'map.list.countMap', payload: res.count });
		})
		.catch(err => {
			dispatch({ type: 'map.list.error', payload: err });
		});
}

export const viewMapList = (startIndex, numItems) => dispatch => {
	dispatch({ type: 'map.list.setLoading' });
	var filter = {
		"skip": startIndex,
		"limit": numItems,
		"include": [{
			"relation": "processing",
			"scope": { "include": [{ "relation": "unit" }] }
		}],
		"order": "unitId, processingId ASC"
	}
	return backendService.getMapList(filter)
		.then(async res => {
			var payload = { index: startIndex, items: res }
			dispatch({ type: 'map.list.viewMapList', payload: payload });
		})
		.catch(err => {
			dispatch({ type: 'map.list.error', payload: err });
		});
}

export const openDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'map.list.openDeletePopup', payload: { id: id, name: name } });
}

export const closeDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'map.list.closeDeletePopup' });
}

export const deleteMap = (id, index, count) => dispatch => {

	return backendService.deleteMap(id)
		.then(res => {
			if (index == count) {
				index = index - config.TABLE_ITEMS_PER_PAGE
			}
			dispatch(countMap());
			dispatch(viewMapList(index, count));
			dispatch({ type: 'map.list.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'map.list.error', payload: err });
		});
}
