import React from 'react';
import { Container, Grid, Image, Dimmer, Loader } from 'semantic-ui-react';
import edit from '../../../../images/edit.png';
import './Details.css';
import ProcessingList from './ProcessingList';
import MeasureList from './MeasureList';

export default function Details(props) {

	var unitEditPath = '/user/unitaProduttiva/modifica/';

	return (
		<Container className='unitDetails'>
			<div className='title-box  pb10'>Dettagli Unità
			{props.cookieSession.isAdmin &&
					<a href='' className='float-right' onClick={(event) => props.pushTo(event, unitEditPath + props.details.data.id)} title='Modifica'>
						<div className='link-box small'>
							<Image src={edit} size='mini' />
						</div>
					</a>
				}
			</div>

			{props.details.isLoading &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			}

			{!props.details.isLoading &&
				<div>
					<Grid columns={2} divided stackable>
						<Grid.Row>
							<Grid.Column>
								Nome: <span className='fieldValue'>{props.details.data.name}</span>
							</Grid.Column>
							<Grid.Column>
								Descrizione: <span className='fieldValue'>{props.details.data.description}</span>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<ProcessingList {...props} />

					<MeasureList {...props} />
				</div>
			}
		</Container>
	);
}
