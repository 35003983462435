import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Edit from './Edit';
import { connect } from 'react-redux';
import { loadProcessingData, editProcessing, getUnitList, unmountEdit } from '../../../../states/user/processing/edit/edit-actions';
import { pushTo } from '../../../../states/common/common-actions';
import Utils from '../../../../utils/Utils';

function EditContainer(props) {

	var cookieSession = Utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		edit: props.edit,
		cookieSession: cookieSession,
		onSubmit: values => props.editProcessing(props.match.params.id, values),
		pushTo: props.pushTo,
		initialValues: props.edit.data
	}

	useEffect(() => {
		props.getUnitList();
		props.loadProcessingData(props.match.params.id);
		return () => props.unmountEdit()
	}, []);

	return (
		<MainLayout {...props}>
			<Edit {...localProps} />
		</MainLayout>
	);
}

export default connect(
	state => ({
		edit: state.processingEdit
	}),
	{ loadProcessingData, editProcessing, getUnitList, unmountEdit, pushTo }
)(EditContainer);
