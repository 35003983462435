import backendService from '../../../../services/BackendService';

export const viewOperatorDetails = (id) => dispatch => {
	dispatch({ type: 'operator.details.setLoading' });
	return backendService.getOperator(id)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'operator.details.viewOperatorDetails', payload: res });
			}
			else {
				dispatch({ type: 'operator.details.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'operator.details.error', payload: err });
		});
}





