const finalUserDetailsState = {
	data: {},
	isLoading: true,
	employeeDeletePopup: false,
	employeeDeletePopupId: 0,
	employeeDeletePopupName: '',
	unitDeletePopup: false,
	unitDeletePopupId: 0,
	unitDeletePopupName: '',
	error: ''
};

export function finalUserDetails(state = finalUserDetailsState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'finalUser.details.viewFinalUserDetails': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'finalUser.details.employeeList.openDeletePopup': {
			return {
				...state,
				employeeDeletePopup: true,
				employeeDeletePopupId: payload.id,
				employeeDeletePopupName: payload.name
			};
		}

		case 'finalUser.details.unitList.openDeletePopup': {
			return {
				...state,
				unitDeletePopup: true,
				unitDeletePopupId: payload.id,
				unitDeletePopupName: payload.name
			};
		}

		case 'finalUser.details.employeeList.closeDeletePopup': {
			return {
				...state,
				employeeDeletePopup: false,
				employeeDeletePopupId: '',
				employeeDeletePopupName: ''
			};
		}

		case 'finalUser.details.unitList.closeDeletePopup': {
			return {
				...state,
				unitDeletePopup: false,
				unitDeletePopupId: '',
				unitDeletePopupName: ''
			};
		}

		case 'finalUser.details.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'finalUser.details.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
