import backendService from '../../../../services/BackendService';

export const createMap = (formData) => dispatch => {
	dispatch({ type: 'map.create.setLoading' });
	var filter = {
		"where": {
			"name": formData.name,
			"processingId": formData.processingId
		}
	}
	return backendService.getMapList(filter)
		.then(async res => {
			if (res.length == 0) {
				if (isValidDate(formData.refDate)) {
					var d = formData.refDate
					var params = {
						name: formData.name,
						description: formData.description,
						refDate: `${d.substring(3, 5)}/${d.substring(0, 2)}/${d.substring(6, 10)}`,
						userId: formData.userId,
						processingId: formData.processingId
					}
					var fd = new FormData();
					fd.append('fileTif', formData.fileTif)
					fd.append('fileSld', formData.fileSld)
					backendService.createMap(params, fd)
						.then(async res => {
							dispatch({ type: 'map.create.createMap' });
						})
						.catch(err => {
							dispatch({ type: 'map.create.error', payload: err });
						});
				}
				else {
					var message = 'Attenzione! Data di riferimento non valida.';
					dispatch({ type: 'map.create.error', payload: message });
				}
			}
			else {
				var message = 'Attenzione! Nome già presente nel sistema per la lavorazione. Creazione non riuscita.';
				dispatch({ type: 'map.create.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'processing.create.error', payload: err });
		});
}

export const getUnitList = () => dispatch => {
	dispatch({ type: 'map.create.unitList.setLoading' });
	var filter = {
		"include": [{ "relation": "processings" }]
	}
	return backendService.getUnitList(filter)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'map.create.getUnitList', payload: res });
			}
			else {
				dispatch({ type: 'map.create.unitList.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'map.create.unitList.error', payload: err });
		});
}

export const unmountCreate = () => dispatch => {
	dispatch({ type: 'map.create.unmountCreate' });
}

function isValidDate(date) {

	var valid = true;

	//date = date.replace('/-/g', '');

	var day = parseInt(date.substring(0, 2), 10);
	var month = parseInt(date.substring(3, 5), 10);
	var year = parseInt(date.substring(6, 10), 10);

	if (isNaN(month) || isNaN(day) || isNaN(year)) return false;

	if ((month < 1) || (month > 12)) valid = false;
	else if ((day < 1) || (day > 31)) valid = false;
	else if (((month == 4) || (month == 6) || (month == 9) || (month == 11)) && (day > 30)) valid = false;
	else if ((month == 2) && (((year % 400) == 0) || ((year % 4) == 0)) && ((year % 100) != 0) && (day > 29)) valid = false;
	else if ((month == 2) && ((year % 100) == 0) && (day > 29)) valid = false;
	else if ((month == 2) && (day > 28)) valid = false;

	return valid;
}
