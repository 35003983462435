const processingListState = {
	index: 0,
	count: 0,
	data: [],
	deletePopup: false,
	deletePopupId: 0,
	deletePopupName: '',
	isLoading: true,
	error: ''
};

export function processingList(state = processingListState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'processing.list.viewProcessingList': {
			return {
				...state,
				index: payload.index,
				data: payload.items,
				isLoading: false
			};
		}

		case 'processing.list.countProcessing': {
			return {
				...state,
				count: payload
			};
		}

		case 'processing.list.openDeletePopup': {
			return {
				...state,
				deletePopup: true,
				deletePopupId: payload.id,
				deletePopupName: payload.name
			};
		}

		case 'processing.list.closeDeletePopup': {
			return {
				...state,
				deletePopup: false,
				deletePopupId: '',
				deletePopupName: ''
			};
		}

		case 'processing.list.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'processing.list.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
