import React from 'react';
import { Container, Table, Image, Button, Modal, Icon } from 'semantic-ui-react';
import details from '../../../../images/details.png';
import edit from '../../../../images/edit.png';
import edit_off from '../../../../images/edit_off.png';
import del from '../../../../images/delete.png';
import del_off from '../../../../images/delete_off.png';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';

export default function MapList(props) {

	var processingMapRefCreatePath = '/user/mappa/crea/';
	var processingMapRefEditPath = '/user/mappa/modifica/';
	var processingMapRefDetailsPath = '/user/mappa/dettagli/';

	return (
		<div>
			<FlexContainer className='row space-between center sub-title'>
				<div>Mappe</div>
				<div>
					{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
						<a href='' onClick={(event) => props.pushTo(event, processingMapRefCreatePath + props.details.data.unitId + '/' + props.id)} title="Crea una mappa della lavorazione">
							<Container fluid className='link-button small'>
								&nbsp;Crea&nbsp;
						</Container>
						</a>
					}
				</div>
			</FlexContainer>
			{props.details.data.hasOwnProperty("maps") &&
				<Table celled className='table small'>
					<Table.Header className='table-header'>
						<Table.Row>
							<Table.HeaderCell textAlign='center'>Nome</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Descrizione</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Azioni</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{[...props.details.data.maps].map((x, i) =>
							<Table.Row key={x.id}>
								<Table.Cell textAlign='center'>{x.name}</Table.Cell>
								<Table.Cell textAlign='center'>{x.description}</Table.Cell>
								<Table.Cell textAlign='center'>
									<FlexContainer className='row center'>
										<a href='' onClick={(event) => props.pushTo(event, processingMapRefDetailsPath + x.id)} title='Visualizza dettagli mappa'>
											<Container fluid className='link-box small'>
												<Image src={details} className='w25' />
											</Container>
										</a>
										<div className='p5'></div>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
											<a href='' onClick={(event) => props.pushTo(event, processingMapRefEditPath + x.id)} title='Modifica mappa'>
												<Container fluid className='link-box small'>
													<Image src={edit} className='w25' />
												</Container>
											</a>
										}
										{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
											<div fluid className='link-box small-off'>
												<Image src={edit_off} className='w25' />
											</div>
										}
										<div className='p5'></div>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
											<a href='' onClick={(event) => props.openMapDeletePopup(event, x.id, x.name)} title='Elimina'>
												<Container fluid className='link-box small'>
													<Image src={del} className='w25' />
												</Container>
											</a>
										}
										{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
											<div fluid className='link-box small-off'>
												<Image src={del_off} className='w25' />
											</div>
										}
									</FlexContainer>
								</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>

					<Table.Footer>

					</Table.Footer>
				</Table>
			}
			{!props.details.data.hasOwnProperty("maps") &&
				<Container fluid className='info-box'>Non ci sono ancora mappe collegate a questa lavorazione</Container>
			}

			<Modal size='tiny' open={props.details.mapDeletePopup}>
				<div className='modal-header'>Messaggio di conferma</div>
				<div className='modal-content'>
					<p>Si è sicuri di voler eliminare la mappa <u>{props.details.mapDeletePopupName}</u>?</p>
				</div>
				<div className='modal-footer'>
					<Button onClick={props.closeMapDeletePopup}><Icon name='remove' />NO</Button>
					<Button onClick={() => props.deleteMap(props.details.mapDeletePopupId, props.details.data.id)}><Icon name='checkmark' />SI</Button>
				</div>
			</Modal>
		</div>
	);



}
