import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Details from './Details';
import { connect } from 'react-redux';
import { pushTo } from '../../../../states/common/common-actions'
import { viewUnitDetails, openProcessingDeletePopup, closeProcessingDeletePopup, deleteProcessing } from '../../../../states/user/unit/details/details-actions';
import utils from '../../../../utils/Utils';

function DetailsContainer(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		details: props.details,
		cookieSession: cookieSession,
		openProcessingDeletePopup: props.openProcessingDeletePopup,
		closeProcessingDeletePopup: props.closeProcessingDeletePopup,
		deleteProcessing: props.deleteProcessing,
		pushTo: props.pushTo,
		id: props.match.params.id
	}

	useEffect(() => {
		props.viewUnitDetails(props.match.params.id)
	}, []);

	return (
		<MainLayout {...props}>
			<Details {...localProps} />
		</MainLayout>
	);

}

export default connect(
	state => ({
		details: state.unitDetails
	}),
	{ viewUnitDetails, openProcessingDeletePopup, closeProcessingDeletePopup, deleteProcessing, pushTo }
)(DetailsContainer);
