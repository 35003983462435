import React from 'react';
import { Grid, Form, Button, Message, Icon, Image } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import logo from '../../images/vintes-logo.png';
import './Login.css';

const required = value => value ? undefined : 'Required';

function Login(props) {

	var passwordRecoverPath = '/passwordRecover/firstStep';

	const { handleSubmit, invalid, submitting, pristine } = props;
	return (
		<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' className='login'>
			<Grid.Column textAlign='left' style={{ maxWidth: 450 }}>

				<div className='login header'>
					<Image src={logo} centered />
				</div>

				<Form name='signup' onSubmit={handleSubmit}>
					<Field name="email" component={Form.Input} validate={[required]} type="email" icon='user' iconPosition='left' placeholder="Email" />
					<Field name="password" component={Form.Input} validate={[required]} type="password" icon='lock' iconPosition='left' placeholder="Password" />
					<Form.Group widths='equal'>
						{/* <Form.Field><a href='' className='login register'>Registrati</a></Form.Field> */}
						<Form.Field><a href='' onClick={(event) => props.pushTo(event, passwordRecoverPath)} title="Recupera password" className='login recover'>Dimenticata la password?</a></Form.Field>
					</Form.Group>
					<Button type='submit' className='login button' disabled={invalid || submitting || pristine}>Accedi</Button>
				</Form>

				{props.login.error != '' &&
					<div className="ui red message">{props.login.error}</div>
				}

			</Grid.Column>
		</Grid>
	);
}

Login = reduxForm({
	form: 'login'
})(Login)

export default Login
