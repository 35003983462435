import backendService from '../../../../services/BackendService';

export const loadUnitData = (id) => dispatch => {
	dispatch({ type: 'unit.edit.setLoading' });
	return backendService.getUnit(id)
		.then(async res => {
			res.namePrev = res.name
			dispatch({ type: 'unit.edit.loadData', payload: res });
		})
		.catch(err => {
			dispatch({ type: 'unit.edit.error', payload: err });
		});
}

export const editUnit = (id, formData) => dispatch => {
	dispatch({ type: 'unit.edit.setLoading' });
	var filter = {
		"where": {
			"name": formData.name,
			"finalUserId": formData.finalUserId
		}
	}
	return backendService.getUnitList(filter)
		.then(async res => {
			if (res.length == 0 || (res[0].name == formData.namePrev)) {
				delete formData.namePrev;
				backendService.editUnit(id, formData)
					.then(async res => {
						dispatch({ type: 'unit.edit.editUnit' });
					})
					.catch(err => {
						dispatch({ type: 'unit.edit.error', payload: err });
					});
			}
			else {
				var message = 'Attenzione! Nome già presente nel sistema per l\'azienda. Modifica non riuscita.';
				dispatch({ type: 'unit.edit.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'unit.create.error', payload: err });
		});
}

export const getFinalUserList = () => dispatch => {
	dispatch({ type: 'unit.edit.finalUserList.setLoading' });
	return backendService.getFinalUserList({})
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'unit.edit.getFinalUserList', payload: res });
			}
			else {
				dispatch({ type: 'unit.edit.finalUserList.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'unit.edit.finalUserList.error', payload: err });
		});
}

export const unmountEdit = () => dispatch => {
	dispatch({ type: 'unit.edit.unmountEdit' });
}
