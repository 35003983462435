import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Details from './Details';
import { connect } from 'react-redux';
import { pushTo } from '../../../../states/common/common-actions'
import {
	viewFinalUserDetails, openEmployeeDeletePopup, closeEmployeeDeletePopup,
	openUnitDeletePopup, closeUnitDeletePopup, deleteEmployee, deleteUnit
} from '../../../../states/user/finalUser/details/details-actions';
import utils from '../../../../utils/Utils';

function DetailsContainer(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		details: props.details,
		cookieSession: cookieSession,
		openEmployeeDeletePopup: props.openEmployeeDeletePopup,
		closeEmployeeDeletePopup: props.closeEmployeeDeletePopup,
		openUnitDeletePopup: props.openUnitDeletePopup,
		closeUnitDeletePopup: props.closeUnitDeletePopup,
		deleteEmployee: props.deleteEmployee,
		deleteUnit: props.deleteUnit,
		pushTo: props.pushTo,
		id: props.match.params.id
	}

	useEffect(() => {
		props.viewFinalUserDetails(props.match.params.id);
	}, []);

	return (
		<MainLayout {...props}>
			<Details {...localProps} />
		</MainLayout>
	);

}

export default connect(
	state => ({
		details: state.finalUserDetails
	}),
	{
		viewFinalUserDetails, openEmployeeDeletePopup, closeEmployeeDeletePopup,
		openUnitDeletePopup, closeUnitDeletePopup, deleteEmployee, deleteUnit, pushTo
	}
)(DetailsContainer);
