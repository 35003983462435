import React from 'react';
import { Container, Form, Button, Dropdown, Modal, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';
import { Field, reduxForm } from 'redux-form';
import './Edit.css';
import isoCountries from 'i18n-iso-countries';
import countryTelData from 'country-telephone-data';

const required = value => value ? undefined : 'Required';
const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? 'Invalid email address'
		: undefined

const inputField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
}

const mobileInput = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={true}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={true}
			/>
		)
}

function Edit(props) {

	var finalUserListPath = '/user/azienda/lista/';

	const prefixDropdown = field => {

		var data = [];
		isoCountries.registerLocale(require("i18n-iso-countries/langs/it.json"));
		countryTelData.allCountries.forEach(elem => {
			//fix to avoid warning with semantic ui react missing flags
			if (!['aq', 'bq', 'cw', 'gg', 'im', 'je', 'xk', 'bl', 'mf', 'sx', 'ss'].includes(elem.iso2))
				data.push({
					key: elem.iso2,
					value: `+${elem.dialCode}`,
					text: `${isoCountries.getName(elem.iso2, 'it')} (+${elem.dialCode})`,
					flag: elem.iso2
				});
		})

		/*countries.forEach(element => {
			data.push({ key: element.id, text: he.unescape(element.displayName), value: element.id });
		});*/

		return (
			<Dropdown fluid selection className='field'
				options={data}
				value={field.input.value}
				flag="it"
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={true}
			/>
		)
	}

	const { handleSubmit, invalid, submitting, pristine } = props;
	return (
		<Container className='userFinalUserEdit'>
			<div className='title-box'>Modifica Azienda</div>
			{props.edit.isLoading &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted className='strong'>Loading</Loader>
				</Dimmer>
			}
			<Form name='editFinalUser' onSubmit={handleSubmit}>
				<label className='form-label'>Nome Azienda *</label>
				<Field name="name" component={inputField} validate={[required]} type="text" fluid />
				<FlexContainer className='row space-between mb1em'>
					<div className='pr10 w200'>
						<label className='form-label'>Prefisso *</label>
						<Field name="prefix" component={prefixDropdown} type="text" fluid />
					</div>
					<div className='flex-grow1'>
						<label className='form-label'>Numero cellulare *</label>
						<Field name="mobile" component={mobileInput} validate={[required]} type="number" fluid />
					</div>
				</FlexContainer>
				<label className='form-label'>Email</label>
				<Field name="email" component={inputField} validate={[email]} type="email" fluid />
				<Message
					error {...(props.edit.error != '' ? { visible: true } : { hidden: true })}>
					{props.edit.error}
				</Message>
				<Button type='submit' disabled={invalid || submitting || pristine}>Modifica</Button>
			</Form>
			<div className='p30'></div>
			<Modal size='tiny' open={props.edit.finalUserEdited}>
				<div className='modal-header'>Messaggio informativo</div>
				<div className='modal-content'>
					<p>Azienda modificata correttamente. <Icon name='check' /></p>
				</div>
				<div className='modal-footer'>
					<Button onClick={(event) => props.pushTo(event, finalUserListPath)}>OK</Button>
				</div>
			</Modal>
		</Container>
	);
}

Edit = reduxForm({
	form: 'editFinalUser',
	enableReinitialize: true
})(Edit)

export default Edit
