const loginState = {
	isUserLogged: false,
	error: ''
};

export function login(state = loginState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'authentication.login': {
			return {
				...state,
				isUserLogged: payload,
				error: ''
			};
		}

		case 'authentication.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
