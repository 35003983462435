import React from 'react';
import { Container, Table } from 'semantic-ui-react';

import FlexContainer from '../../../Common/FlexContainer/FlexContainer';

export default function MeasureList(props) {

	return (
		<div>
			<FlexContainer className='row space-between center sub-title'>
				<div>Misurazioni</div>
			</FlexContainer>
			{props.details.data.hasOwnProperty("measures") &&
				<Table celled className='table small'>
					<Table.Header className='table-header'>
						<Table.Row>
							<Table.HeaderCell textAlign='center'>Nome</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Unità di misura</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Valore</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Anno</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Posizione</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{[...props.details.data.measures].map((x, i) =>
							<Table.Row key={x.id}>
								<Table.Cell textAlign='center'>{x.parameter.name}</Table.Cell>
								<Table.Cell textAlign='center'>{x.parameter.uom}</Table.Cell>
								<Table.Cell textAlign='center'>{x.value}</Table.Cell>
								<Table.Cell textAlign='center'>{x.year}</Table.Cell>
								<Table.Cell textAlign='center'>{x.location[0]}, {x.location[1]}</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>

					<Table.Footer>

					</Table.Footer>
				</Table>
			}
			{!props.details.data.hasOwnProperty("measures") &&
				<Container fluid className='info-box'>Non ci sono ancora misurazioni collegate a questa unità produttiva</Container>
			}
		</div>
	);

}
