import cookies from 'js-cookie';
const util = require('util');

export function getCookieSession(staticContext) {
	var cookieSession = {};

	if (staticContext === undefined || staticContext === null) {
		if (cookies.get('session') != null && cookies.get('session') != '') {
			cookieSession = JSON.parse(new Buffer(cookies.get('session').toString(), 'base64').toString('ascii'));
		}
	}
	else {
		cookieSession = staticContext;
	}
	return cookieSession;
}

export default {
	getCookieSession
}
