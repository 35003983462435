import backendService from '../../../../services/BackendService';
import config from '../../../../config/config.app';

export const countOperator = () => dispatch => {
	dispatch({ type: 'operator.list.setLoading' });
	return backendService.countOperator()
		.then(res => {
			dispatch({ type: 'operator.list.countOperator', payload: res.count });
		})
		.catch(err => {
			dispatch({ type: 'operator.list.error', payload: err });
		});
}

export const viewOperatorList = (startIndex, numItems) => dispatch => {
	dispatch({ type: 'operator.list.setLoading' });
	var filter = {
		"skip": startIndex,
		"limit": numItems
	}
	return backendService.getOperatorList(filter)
		.then(async res => {
			var payload = { index: startIndex, items: res }
			dispatch({ type: 'operator.list.viewOperatorList', payload: payload });
		})
		.catch(err => {
			dispatch({ type: 'operator.list.error', payload: err });
		});
}

export const openDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'operator.list.openDeletePopup', payload: { id: id, name: name } });
}

export const closeDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'operator.list.closeDeletePopup' });
}

export const deleteOperator = (id, index, count) => dispatch => {

	return backendService.deleteOperator(id)
		.then(res => {
			if (index == count) {
				index = index - config.TABLE_ITEMS_PER_PAGE
			}
			dispatch(countOperator());
			dispatch(viewOperatorList(index, count));
			dispatch({ type: 'operator.list.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'operator.list.error', payload: err });
		});
}
