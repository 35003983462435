const processingCreateState = {
	processingCreated: false,
	unitList: [],
	unitListLoaded: false,
	isLoading: false,
	error: '',
	unitListError: ''
};

export function processingCreate(state = processingCreateState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'processing.create.createProcessing': {
			return {
				...state,
				processingCreated: true,
				isLoading: false
			};
		}

		case 'processing.create.getUnitList': {
			return {
				...state,
				unitList: payload,
				unitListLoaded: true
			};
		}

		case 'processing.create.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'processing.create.unitList.setLoading': {
			return {
				...state,
				unitListLoaded: false
			};
		}

		case 'processing.create.unmountCreate': {
			return {
				...state,
				processingCreated: false
			};
		}

		case 'processing.create.error': {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		}

		case 'processing.create.unitList.error': {
			return {
				...state,
				unitListError: payload
			};
		}

		default:
			return state;
	}
}
