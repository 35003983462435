import React from 'react';
import Router from './routes/Router';
import './App.css';

//import 'font-awesome/css/font-awesome.min.css'; //Font Awesome css
import 'semantic-ui-css/semantic.min.css'; //Semantic Ui React css

const App = (props) => (
	<Router {...props} />
);

export default App;
