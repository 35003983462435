const secondStepState = {
	message: '',
	completed: false,
	error: ''
};

export function secondStep(state = secondStepState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'secondStep.completeRecover': {
			return {
				...state,
				message: payload,
				completed: true,
				error: ''
			};
		}

		case 'secondStep.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
