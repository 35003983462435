import backendService from '../../../../services/BackendService';

export const loadMapData = (id) => dispatch => {
	dispatch({ type: 'map.edit.setLoading' });
	return backendService.getMap(id)
		.then(async res => {
			res.namePrev = res.name
			res.refDate = (new Date(res.refDate)).toLocaleString("it-IT", { day: '2-digit', month: '2-digit', year: 'numeric' })
			dispatch({ type: 'map.edit.loadData', payload: res });
		})
		.catch(err => {
			dispatch({ type: 'map.edit.error', payload: err });
		});
}

export const editMap = (id, formData) => dispatch => {
	dispatch({ type: 'map.edit.setLoading' });
	var filter = {
		"where": {
			"name": formData.name,
			"processingId": formData.processingId
		}
	}
	return backendService.getMapList(filter)
		.then(async res => {
			if (res.length == 0 || (res[0].name == formData.namePrev)) {
				if (isValidDate(formData.refDate)) {
					delete formData.namePrev
					var d = formData.refDate
					var params = {
						name: formData.name,
						description: formData.description,
						refDate: `${d.substring(3, 5)}/${d.substring(0, 2)}/${d.substring(6, 10)}`,
						userId: formData.userId,
						processingId: formData.processingId
					}
					var fd = new FormData();
					fd.append('fileTif', formData.fileTif)
					fd.append('fileSld', formData.fileSld)
					backendService.editMap(id, params, fd)
						.then(async res => {
							dispatch({ type: 'map.edit.editMap' });
						})
						.catch(err => {
							dispatch({ type: 'map.edit.error', payload: err });
						});
				}
				else {
					var message = 'Attenzione! Data di riferimento non valida.';
					dispatch({ type: 'map.edit.error', payload: message });
				}
			}
			else {
				var message = 'Attenzione! Nome già presente nel sistema per la lavorazione. Modifica non riuscita.';
				dispatch({ type: 'map.edit.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'map.edit.error', payload: err });
		});
}

export const getUnitList = () => dispatch => {
	dispatch({ type: 'map.edit.unitList.setLoading' });
	var filter = {
		"include": [{ "relation": "processings" }]
	}
	return backendService.getUnitList(filter)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'map.edit.getUnitList', payload: res });
			}
			else {
				dispatch({ type: 'map.edit.unitList.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'map.edit.unitList.error', payload: err });
		});
}

export const unmountEdit = () => dispatch => {
	dispatch({ type: 'map.edit.unmountEdit' });
}

function isValidDate(date) {

	var valid = true;

	//date = date.replace('/-/g', '');

	var day = parseInt(date.substring(0, 2), 10);
	var month = parseInt(date.substring(3, 5), 10);
	var year = parseInt(date.substring(6, 10), 10);

	if (isNaN(month) || isNaN(day) || isNaN(year)) return false;

	if ((month < 1) || (month > 12)) valid = false;
	else if ((day < 1) || (day > 31)) valid = false;
	else if (((month == 4) || (month == 6) || (month == 9) || (month == 11)) && (day > 30)) valid = false;
	else if ((month == 2) && (((year % 400) == 0) || ((year % 4) == 0)) && ((year % 100) != 0) && (day > 29)) valid = false;
	else if ((month == 2) && ((year % 100) == 0) && (day > 29)) valid = false;
	else if ((month == 2) && (day > 28)) valid = false;

	return valid;
}
