const unitEditState = {
	data: {},
	unitEdited: false,
	finalUserList: [],
	finalUserListLoaded: false,
	isLoading: false,
	error: '',
	finalUserListError: ''
};

export function unitEdit(state = unitEditState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'unit.edit.loadData': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'unit.edit.editUnit': {
			return {
				...state,
				unitEdited: true,
				isLoading: false
			};
		}

		case 'unit.edit.getFinalUserList': {
			return {
				...state,
				finalUserList: payload,
				finalUserListLoaded: true
			};
		}

		case 'unit.edit.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'unit.edit.finalUserList.setLoading': {
			return {
				...state,
				finalUserListLoaded: false
			};
		}

		case 'unit.edit.unmountEdit': {
			return {
				...state,
				unitEdited: false
			};
		}

		case 'unit.edit.error': {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		}

		case 'unit.edit.finalUserList.error': {
			return {
				...state,
				finalUserListError: payload
			};
		}

		default:
			return state;
	}
}
