import backendService from '../../../../services/BackendService';

export const createProcessing = (formData) => dispatch => {
	dispatch({ type: 'processing.create.setLoading' });
	var filter = {
		"where": {
			"name": formData.name,
			"unitId": formData.unitId
		}
	}
	return backendService.getProcessingList(filter)
		.then(async res => {
			if (res.length == 0)
				backendService.createProcessing(formData)
					.then(async res => {
						dispatch({ type: 'processing.create.createProcessing' });
					})
					.catch(err => {
						dispatch({ type: 'processing.create.error', payload: err });
					});
			else {
				var message = 'Attenzione! Nome già presente nel sistema per l\'unità produttiva. Creazione non riuscita.';
				dispatch({ type: 'processing.create.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'processing.create.error', payload: err });
		});
}

export const getUnitList = () => dispatch => {
	dispatch({ type: 'processing.create.unitList.setLoading' });
	return backendService.getUnitList({})
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'processing.create.getUnitList', payload: res });
			}
			else {
				dispatch({ type: 'processing.create.unitList.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'processing.create.unitList.error', payload: err });
		});
}

export const unmountCreate = () => dispatch => {
	dispatch({ type: 'processing.create.unmountCreate' });
}
