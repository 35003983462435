import React from 'react';
import FlexContainer from '../../Common/FlexContainer/FlexContainer';
import utils from '../../../utils/Utils';
import './Sidebar.css';

export default function Sidebar(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);

	var homePath = /*cookieSession.isAdmin ? '/admin/home' :*/ '/user/home';
	var finalUserListPath = '/user/azienda/lista';
	var unitListPath = '/user/unitaProduttiva/lista';
	var processingListPath = '/user/lavorazione/lista'
	var mapListPath = '/user/mappa/lista';
	var operatorListPath = '/user/operatore/lista';

	var goTo = function (event, homePath) {
		//Trick to close floating menu on mobile devices
		var sidebarElem = document.querySelector('#sidebar');
		sidebarElem.style.display = 'none';
		props.pushTo(event, homePath);
	}

	return (
		<FlexContainer id='sidebar' className='column center sidebar'>

			<a href='' onClick={(event) => goTo(event, homePath)}>
				<div className='item'>Home</div></a>

			<a href='' onClick={(event) => goTo(event, finalUserListPath)}>
				<div className='item'>Aziende</div></a>

			<a href='' onClick={(event) => goTo(event, unitListPath)}>
				<div className='item'>Unità produttive</div></a>

			<a href='' onClick={(event) => goTo(event, processingListPath)}>
				<div className='item'>Lavorazioni</div></a>

			<a href='' onClick={(event) => goTo(event, mapListPath)}>
				<div className='item'>Mappe</div></a>

			<a href='' onClick={(event) => goTo(event, operatorListPath)}>
				<div className='item'>Operatori</div></a>

		</FlexContainer>
	);
}

