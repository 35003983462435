const processingDetailsState = {
	data: {},
	mapList: [],
	isLoading: true,
	mapDeletePopup: false,
	mapDeletePopupId: 0,
	mapDeletePopupName: '',
	error: ''
};

export function processingDetails(state = processingDetailsState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'processing.details.viewProcessingDetails': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'processing.details.mapList.openDeletePopup': {
			return {
				...state,
				mapDeletePopup: true,
				mapDeletePopupId: payload.id,
				mapDeletePopupName: payload.name
			};
		}

		case 'processing.details.mapList.closeDeletePopup': {
			return {
				...state,
				mapDeletePopup: false,
				mapDeletePopupId: '',
				mapDeletePopupName: ''
			};
		}

		case 'processing.details.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'processing.details.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
