import backendService from '../../../../services/BackendService';
import mapboxgl from 'mapbox-gl';
import config from '../../../../config/config.app';

export const viewMapDetails = (id) => dispatch => {
	dispatch({ type: 'map.details.setLoading' });
	var filter = {
		"include": [{
			"relation": "processing",
			"scope": {
				"include": [{
					"relation": "unit",
					"scope": {
						"include": [{ "relation": "finalUser" }]
					}
				}]
			}
		}]
	}
	return backendService.getMap(id, filter)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'map.details.viewMapDetails', payload: res });
			}
			else {
				dispatch({ type: 'map.details.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'map.details.error', payload: err });
		});
}

export const createMap = (map) => dispatch => {
	dispatch({ type: 'map.details.map.setLoading' });
	dispatch({ type: 'map.details.createMap', payload: map });
}




