import React from 'react';
import { Container, Dimmer, Loader, Image, Table, Menu, Modal, Button, Icon } from 'semantic-ui-react';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';
import edit from '../../../../images/edit.png';
import edit_off from '../../../../images/edit_off.png';
import del from '../../../../images/delete.png';
import del_off from '../../../../images/delete_off.png';
import details from '../../../../images/details.png';
import './List.css';
import configApp from '../../../../config/config.app';

export default function List(props) {

	var finalUserDetailsPath = '/user/azienda/dettagli/';
	var finalUserEditPath = '/user/azienda/modifica/';
	var finalUserCreatePath = '/user/azienda/crea/';

	function createPagination(startIndex, total) {
		var rows = [];

		var numTotalPages = Math.ceil(total / configApp.TABLE_ITEMS_PER_PAGE);
		var numCurrentPage = startIndex / configApp.TABLE_ITEMS_PER_PAGE + 1;
		var paginationIntervalNumber = Math.ceil(numCurrentPage / configApp.PAGINATION_ELEMENTS);
		var paginationIntervalStart = configApp.PAGINATION_ELEMENTS * (paginationIntervalNumber - 1) + 1;
		var paginationIntervalEnd =
			numTotalPages < configApp.PAGINATION_ELEMENTS * paginationIntervalNumber ?
				numTotalPages : configApp.PAGINATION_ELEMENTS * paginationIntervalNumber;

		rows.push(<Menu.Item as='a' className='pagination-item' icon key='<<' onClick={() => {
			props.viewFinalUserList(0, configApp.TABLE_ITEMS_PER_PAGE)
		}}><Icon name='angle double left' /></Menu.Item>);
		rows.push(<Menu.Item as='a' className='pagination-item' icon key='<' onClick={() => {
			props.viewFinalUserList(startIndex - configApp.TABLE_ITEMS_PER_PAGE, configApp.TABLE_ITEMS_PER_PAGE)
		}} disabled={(numCurrentPage > 1) ? false : true}><Icon name='angle left' /></Menu.Item>);

		if (paginationIntervalNumber > 1)
			rows.push(<Menu.Item as='a' key='...<' className='pagination-item' onClick={() => {
				props.viewFinalUserList(configApp.TABLE_ITEMS_PER_PAGE * (paginationIntervalStart - 2), configApp.TABLE_ITEMS_PER_PAGE)
			}}>...</Menu.Item>);

		for (let i = paginationIntervalStart; i <= paginationIntervalEnd; i++) {
			rows.push(
				<Menu.Item as='a' className='pagination-item' key={i} onClick={() => {
					props.viewFinalUserList(configApp.TABLE_ITEMS_PER_PAGE * (i - 1), configApp.TABLE_ITEMS_PER_PAGE)
				}}
					active={numCurrentPage == i ? true : false}>{i}</Menu.Item>
			);
		}

		if (paginationIntervalEnd < numTotalPages)
			rows.push(<Menu.Item as='a' className='pagination-item' key='>...' onClick={() => {
				props.viewFinalUserList(configApp.TABLE_ITEMS_PER_PAGE * (paginationIntervalEnd), configApp.TABLE_ITEMS_PER_PAGE)
			}}>...</Menu.Item>);

		rows.push(<Menu.Item as='a' className='pagination-item' icon key='>' onClick={() => {
			props.viewFinalUserList(startIndex + configApp.TABLE_ITEMS_PER_PAGE, configApp.TABLE_ITEMS_PER_PAGE)
		}} disabled={(numCurrentPage < numTotalPages) ? false : true}><Icon name='angle right' /></Menu.Item>);

		rows.push(<Menu.Item as='a' className='pagination-item' icon key='>>' onClick={() => {
			props.viewFinalUserList(configApp.TABLE_ITEMS_PER_PAGE * (numTotalPages - 1), configApp.TABLE_ITEMS_PER_PAGE)
		}}><Icon name='angle double right' /></Menu.Item>);

		return rows;
	}

	return (
		<Container>
			<div className='title-box'>Aziende</div>
			<FlexContainer className='row flex-end center sub-title'>
				<div>
					{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
						<a href='' onClick={(event) => props.pushTo(event, finalUserCreatePath)} title="Crea una nuova azienda">
							<Container fluid className='link-button small'>
								&nbsp;Crea&nbsp;
							</Container>
						</a>
					}
					{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
						<Container fluid className='link-button small-off'>
							&nbsp;Crea&nbsp;
						</Container>
					}
				</div>
			</FlexContainer>

			{
				props.list.isLoading &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted className='strong'>Loading</Loader>
				</Dimmer>
			}

			<Table fixed column={5} celled className='table'>
				<Table.Header className='table-header'>
					<Table.Row>
						<Table.HeaderCell textAlign='center'>Nome</Table.HeaderCell>
						<Table.HeaderCell textAlign='center'>Cellulare</Table.HeaderCell>
						<Table.HeaderCell textAlign='center'>Email</Table.HeaderCell>
						<Table.HeaderCell textAlign='center' className='w300'>Dettagli</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{[...props.list.data].map((x, i) =>
						<Table.Row key={x.id}>
							<Table.Cell textAlign='center'>{x.name}</Table.Cell>
							<Table.Cell textAlign='center'>{x.prefix + " " + x.mobile}</Table.Cell>
							<Table.Cell textAlign='center'>{x.email}</Table.Cell>
							<Table.Cell textAlign='center'>
								<FlexContainer className='row center'>

									<a href='' onClick={(event) => props.pushTo(event, finalUserDetailsPath + x.id)} title='Visualizza dettagli'>
										<div className='link-box small'>
											<Image src={details} size='mini' />
										</div>
									</a>
									<div className='p5'></div>
									{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
										<a href='' onClick={(event) => props.pushTo(event, finalUserEditPath + x.id)} title='Modifica'>
											<div className='link-box small'>
												<Image src={edit} size='mini' />
											</div>
										</a>
									}
									{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
										<div className='link-box small-off'>
											<Image src={edit_off} size='mini' />
										</div>
									}
									<div className='p5'></div>
									{(props.cookieSession.isAdmin || props.cookieSession.isOperator) && x.isDeletable &&
										<a href='' onClick={(event) => props.openDeletePopup(event, x.id, x.name)} title='Elimina'>
											<div className='link-box small'>
												<Image src={del} size='mini' />
											</div>
										</a>
									}
									{(!props.cookieSession.isAdmin && !props.cookieSession.isOperator) || !x.isDeletable &&
										<div className='link-box small-off'>
											<Image src={del_off} size='mini' />
										</div>
									}
								</FlexContainer>
							</Table.Cell>
						</Table.Row>
					)}
				</Table.Body>

				<Table.Footer>
					{props.list.count > configApp.TABLE_ITEMS_PER_PAGE &&
						<Table.Row>
							<Table.HeaderCell colSpan='4' textAlign='center'>
								<Menu pagination>
									{createPagination(props.list.index, props.list.count)}
								</Menu>
							</Table.HeaderCell>
						</Table.Row>
					}
				</Table.Footer>
			</Table>

			{
				!props.list.isLoading && props.list.data.length == 0 &&
				<Container fluid className='info-box'>Non ci sono aziende presenti'</Container>
			}
			<div className='p30'></div>
			<Modal size='tiny' open={props.list.deletePopup}>
				<div className='modal-header'>Messaggio di conferma</div>
				<div className='modal-content'>
					<p>Si è sicuri di voler eliminare l'azienda <u>{props.list.deletePopupName}</u>?</p>
				</div>
				<div className='modal-footer'>
					<Button onClick={props.closeDeletePopup}><Icon name='remove' />NO</Button>
					<Button onClick={() => props.deleteFinalUser(props.list.deletePopupId, props.list.index, configApp.TABLE_ITEMS_PER_PAGE)}><Icon name='checkmark' />SI</Button>
				</div>
			</Modal>

		</Container>
	);
}
