const unitDetailsState = {
	data: {},
	processingList: [],
	isLoading: true,
	processingDeletePopup: false,
	processingDeletePopupId: 0,
	processingDeletePopupName: '',
	error: ''
};

export function unitDetails(state = unitDetailsState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'unit.details.viewUnitDetails': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'unit.details.processingList.openDeletePopup': {
			return {
				...state,
				processingDeletePopup: true,
				processingDeletePopupId: payload.id,
				processingDeletePopupName: payload.name
			};
		}

		case 'unit.details.processingList.closeDeletePopup': {
			return {
				...state,
				processingDeletePopup: false,
				processingDeletePopupId: '',
				processingDeletePopupName: ''
			};
		}

		case 'unit.details.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'unit.details.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
