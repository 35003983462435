import backendService from '../../../services/BackendService';

export const completeRecover = (resetKey, userDataObj) => dispatch => {
	return backendService.resetPassword({ ...userDataObj, resetKey })
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'secondStep.completeRecover', payload: res });
			}
			else {
				dispatch({ type: 'secondStep.error', payload: res.error.message });
			}
		})
	/*.catch(err => {
		dispatch({ type: 'secondStep.completeRecover', payload: err.message });
	});*/
}
