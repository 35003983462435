import backendService from '../../../../services/BackendService';

export const viewFinalUserDetails = (id) => dispatch => {
	dispatch({ type: 'finalUser.details.setLoading' });
	var filter = {
		"include": [{ "relation": "units" }, { "relation": "employees" }]
	}
	return backendService.getFinalUser(id, filter)
		.then(async res => {
			if (!res.error) {

				if (res.hasOwnProperty("units")) {
					for (var i = 0; i < res.units.length; i++) {
						var res2 = await backendService.isUnitDeletable(res.units[i].id)
						res.units[i].isDeletable = res2.isDeletable;
					}
				}

				dispatch({ type: 'finalUser.details.viewFinalUserDetails', payload: res });
			}
			else {
				dispatch({ type: 'finalUser.details.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'finalUser.details.error', payload: err });
		});
}

export const openEmployeeDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'finalUser.details.employeeList.openDeletePopup', payload: { id: id, name: name } });
}

export const closeEmployeeDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'finalUser.details.employeeList.closeDeletePopup' });
}

export const deleteEmployee = (id, finalUserId) => dispatch => {

	return backendService.deleteEmployee(id)
		.then(res => {
			dispatch(viewFinalUserDetails(finalUserId));
			dispatch({ type: 'finalUser.details.employeeList.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.details.employeeList.error', payload: err });
		});
}

export const openUnitDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'finalUser.details.unitList.openDeletePopup', payload: { id: id, name: name } });
}

export const closeUnitDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'finalUser.details.unitList.closeDeletePopup' });
}

export const deleteUnit = (id, finalUserId) => dispatch => {
	return backendService.deleteUnit(id)
		.then(res => {
			dispatch(viewFinalUserDetails(finalUserId));
			dispatch({ type: 'finalUser.details.unitList.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.details.unitList.error', payload: err });
		});
}





