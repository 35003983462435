const finalUserCreateState = {
	finalUserCreated: false,
	isLoading: false,
	error: ''
};

export function finalUserCreate(state = finalUserCreateState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'finalUser.create.createFinalUser': {
			return {
				...state,
				finalUserCreated: true,
				isLoading: false,
				exists: false
			};
		}

		case 'finalUser.create.unmountCreate': {
			return {
				...state,
				finalUserCreated: false
			};
		}

		case 'finalUser.create.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'finalUser.create.error': {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		}

		default:
			return state;
	}
}
