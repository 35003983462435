import React from 'react';
import { Grid, Form, Button, Message, Icon, Image } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import logo from '../../../images/vintes-logo.png';
import './FirstStep.css';

const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? 'Invalid email address'
		: undefined

function FirstStep(props) {
	const { handleSubmit, invalid, submitting, pristine } = props;
	return (
		<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' className='login'>
			<Grid.Column textAlign='left' style={{ maxWidth: 450 }}>

				<div className='firstStep header'>
					<Image src={logo} centered />
				</div>

				<div className='firstStep header'>
					Inserisci l'indirizzo mail associato al tuo account e clicca sul pulsante <strong>Recupera</strong>.<br />
					Riceverai una mail con le istruzioni per cambiare la password.<br />
				</div>

				<Form name='firstStep' onSubmit={handleSubmit}>
					<Field name="email" component={Form.Input} validate={[email]} type="email" icon='mail' iconPosition='left' placeholder="Email" />
					<Button type='submit' className='firstStep button' disabled={invalid || submitting || pristine}>Recupera</Button>
				</Form>

				{props.firstStep.message != '' &&
					<div className="ui green message">{props.firstStep.message}</div>
				}
				{props.firstStep.error != '' &&
					<div className="ui red message">{props.firstStep.error}</div>
				}

			</Grid.Column>
		</Grid>
	);
}

FirstStep = reduxForm({
	form: 'firstStep'
})(FirstStep)

export default FirstStep
