const operatorDetailsState = {
	data: {},
	isLoading: true,
	error: ''
};

export function operatorDetails(state = operatorDetailsState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'operator.details.viewOperatorDetails': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'operator.details.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'operator.details.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
