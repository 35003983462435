const mapDetailsState = {
	data: {},
	map: {},
	isLoading: true,
	isMapLoading: true,
	error: ''
};

export function mapDetails(state = mapDetailsState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'map.details.viewMapDetails': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'map.details.createMap': {
			return {
				...state,
				map: payload,
				isMapLoading: false
			};
		}

		case 'map.details.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'map.details.map.setLoading': {
			return {
				...state,
				isMapLoading: true
			};
		}

		case 'map.details.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
