import backendService from '../../../../services/BackendService';

export const viewProcessingDetails = (id) => dispatch => {
	dispatch({ type: 'processing.details.setLoading' });
	var filter = {
		"include": [{ "relation": "maps" }]
	}
	return backendService.getProcessing(id, filter)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'processing.details.viewProcessingDetails', payload: res });
			}
			else {
				dispatch({ type: 'processing.details.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'processing.details.error', payload: err });
		});
}

export const openMapDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'processing.details.mapList.openDeletePopup', payload: { id: id, name: name } });
}

export const closeMapDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'processing.details.mapList.closeDeletePopup' });
}

export const deleteMap = (id, unitId) => dispatch => {
	return backendService.deleteMap(id)
		.then(res => {
			dispatch(viewProcessingDetails(unitId));
			dispatch({ type: 'processing.details.mapList.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'processing.details.mapList.error', payload: err });
		});
}

