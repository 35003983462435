import React from 'react';
import { Container, Form, Button, Dropdown, Modal, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';
import { Field, reduxForm } from 'redux-form';
import './Edit.css';

const required = value => value ? undefined : 'Required';
const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? 'Invalid email address'
		: undefined;
const passwordMinLength = min => value =>
	value && value.length < min ? `La password deve avere ${min} o più caratteri` : undefined;
const passwordMinLength6 = passwordMinLength(6)

const inputField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
}

const mailField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={true}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={true}
			/>
		)
}

const passwordField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				type="password"
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				type="password"
			/>
		)
}

function Edit(props) {

	var operatorListPath = '/user/operatore/lista/';

	const { handleSubmit, invalid, submitting, pristine } = props;
	return (
		<Container className='userOperatorEdit'>
			<div className='title-box'>Modifica Operatore</div>
			{props.edit.isLoading &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted className='strong'>Loading</Loader>
				</Dimmer>
			}
			<Form name='editFinalUser' onSubmit={handleSubmit}>
				<label className='form-label'>Nome</label>
				<Field name="firstName" component={inputField} type="text" fluid />
				<label className='form-label'>Cognome</label>
				<Field name="lastName" component={inputField} type="text" fluid />
				<label className='form-label'>Username</label>
				<Field name="username" component={inputField} type="text" fluid />
				<label className='form-label'>Email *</label>
				<Field name="email" component={mailField} validate={[email]} type="email" fluid />
				<label className='form-label'>Password *</label>
				<Field name="password" component={passwordField} validate={[passwordMinLength6]} type="password" fluid />
				<Message
					error {...(props.edit.error != '' ? { visible: true } : { hidden: true })}>
					{props.edit.error}
				</Message>
				<Button type='submit' disabled={invalid || submitting || pristine}>Modifica</Button>
			</Form>
			<div className='p30'></div>
			<Modal size='tiny' open={props.edit.operatorEdited}>
				<div className='modal-header'>Messaggio informativo</div>
				<div className='modal-content'>
					<p>Operatore modificato correttamente. <Icon name='check' /></p>
				</div>
				<div className='modal-footer'>
					<Button onClick={(event) => props.pushTo(event, operatorListPath)}>OK</Button>
				</div>
			</Modal>
		</Container>
	);
}

Edit = reduxForm({
	form: 'editOperator',
	enableReinitialize: true
})(Edit)

export default Edit
