import backendService from '../../../../services/BackendService';
import config from '../../../../config/config.app';

export const countProcessing = () => dispatch => {
	dispatch({ type: 'processing.list.setLoading' });
	return backendService.countProcessing()
		.then(res => {
			dispatch({ type: 'processing.list.countProcessing', payload: res.count });
		})
		.catch(err => {
			dispatch({ type: 'processing.list.error', payload: err });
		});
}

export const viewProcessingList = (startIndex, numItems) => dispatch => {
	dispatch({ type: 'processing.list.setLoading' });
	var filter = {
		"skip": startIndex,
		"limit": numItems,
		"include": [{ "relation": "unit" }],
		"order": "unitId ASC"
	}
	return backendService.getProcessingList(filter)
		.then(async res => {

			for (var i = 0; i < res.length; i++) {
				var res2 = await backendService.isProcessingDeletable(res[i].id)
				res[i].isDeletable = res2.isDeletable;
			}

			var payload = { index: startIndex, items: res }
			dispatch({ type: 'processing.list.viewProcessingList', payload: payload });
		})
		.catch(err => {
			dispatch({ type: 'processing.list.error', payload: err });
		});
}

export const openDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'processing.list.openDeletePopup', payload: { id: id, name: name } });
}

export const closeDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'processing.list.closeDeletePopup' });
}

export const deleteProcessing = (id, index, count) => dispatch => {

	return backendService.deleteProcessing(id)
		.then(res => {
			if (index == count) {
				index = index - config.TABLE_ITEMS_PER_PAGE
			}
			dispatch(countProcessing());
			dispatch(viewProcessingList(index, count));
			dispatch({ type: 'processing.list.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'processing.list.error', payload: err });
		});
}
