import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Create from './Create';
import { connect } from 'react-redux';
import { createUnit, getFinalUserList, unmountCreate } from '../../../../states/user/unit/create/create-actions';
import { pushTo } from '../../../../states/common/common-actions';
import Utils from '../../../../utils/Utils';

function CreateContainer(props) {

	var cookieSession = Utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		create: props.create,
		cookieSession: cookieSession,
		onSubmit: props.createUnit,
		pushTo: props.pushTo,
		initialValues: { finalUserId: props.match.params.finalUserId }
	}

	useEffect(() => {
		props.getFinalUserList();
		return () => props.unmountCreate()
	}, []);

	return (
		<MainLayout {...props}>
			<Create {...localProps} />
		</MainLayout>
	);
}

export default connect(
	state => ({
		create: state.unitCreate
	}),
	{ createUnit, getFinalUserList, unmountCreate, pushTo }
)(CreateContainer);
