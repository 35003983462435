const VARS = {
	AUTH_KEY: 'auth_vintes',
	DEFAULT_LANG: 'it',
	TABLE_ITEMS_PER_PAGE: 10,
	PAGINATION_ELEMENTS: 15,
	FINAL_USER: 'finalUser',
	OPERATOR: 'operator',
	MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoidW5jbGVwZXRyb3MiLCJhIjoiY2thY3NpdXpkMWpxNTJ6cDQ2ajgzZmxkdiJ9.JmSidiFCq_JNXTyWkkD24Q'
};

export default VARS;
