import React from 'react';
import classNames from 'classnames';
import './FlexContainer.css';

export default function FlexContainer(props) {
	return (
      <div id={props.id}
          className={classNames('flexContainer', props.className)}>
          {props.children}
      </div>
    );
};