import { createStore, applyMiddleware } from "redux";
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';
import { rootReducer } from "./index";
import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();
const connectedRouter = routerMiddleware(history);
const logger = createLogger();

var store = createStore(
	combineReducers({ router: connectRouter(history), ...rootReducer, form: formReducer }), 
	applyMiddleware(connectedRouter, thunk, logger)
);

export default store;