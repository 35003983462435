import React from 'react';
import { Container, Form, Button, Dropdown, Modal, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import './Edit.css';

const required = value => value ? undefined : 'Required';
const inputField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
}

function Edit(props) {

	const finalUserDropdown = field => {

		var data = [];

		props.edit.finalUserList.forEach(element => {
			data.push({ key: element.id, text: element.name, value: element.id });
		});

		return (
			<Dropdown fluid search selection className='field'
				options={data}
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={true}
			/>
		)
	}

	var unitListPath = '/user/unitaProduttiva/lista/';

	const { handleSubmit, invalid, submitting, pristine } = props;

	return (
		<Container className='unitEdit'>
			<div className='title-box'>Modifica unità produttiva</div>
			{(props.edit.isLoading || !props.edit.finalUserListLoaded) &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted className='strong'>Loading</Loader>
				</Dimmer>
			}
			<Form name='editUnit' onSubmit={handleSubmit}>
				<label className='form-label'>Nome Unità *</label>
				<Field name="name" component={inputField} validate={[required]} type="text" fluid />
				<Field name="namePrev" component="input" type="text" hidden />
				<label className='form-label'>Descrizione</label>
				<Field name="description" component={inputField} type="text" fluid />
				<label className='form-label'>Azienda *</label>
				<Field name="finalUserId" component={finalUserDropdown} validate={[required]} type="text" fluid />
				<Message
					error {...(props.edit.error != '' ? { visible: true } : { hidden: true })}>
					{props.edit.error}
				</Message>
				<Button type='submit' disabled={invalid || submitting || pristine}>Modifica</Button>
			</Form>
			<div className='p30'></div>
			<Modal size='tiny' open={props.edit.unitEdited}>
				<div className='modal-header'>Messaggio informativo</div>
				<div className='modal-content'>
					<p>Unità modificata correttamente. <Icon name='check' /></p>
				</div>
				<div className='modal-footer'>
					<Button onClick={(event) => props.pushTo(event, unitListPath)}>OK</Button>
				</div>
			</Modal>
		</Container>
	);
}

Edit = reduxForm({
	form: 'editUnit',
	enableReinitialize: true
})(Edit)

export default Edit
