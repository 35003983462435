import backendService from '../../../../services/BackendService';

export const loadOperatorData = (id) => dispatch => {
	dispatch({ type: 'operator.edit.setLoading' });
	return backendService.getOperator(id)
		.then(async res => {
			res.password = '';
			dispatch({ type: 'operator.edit.loadOperatorData', payload: res });
		})
		.catch(err => {
			dispatch({ type: 'operator.edit.error', payload: err });
		});
}

export const editOperator = (id, formData) => dispatch => {
	dispatch({ type: 'operator.edit.setLoading' });

	return backendService.editOperator(id, formData)
		.then(async res => {
			dispatch({ type: 'operator.edit.editOperator', payload: res });
		})
		.catch(err => {
			dispatch({ type: 'operator.edit.error', payload: err });
		});
}

export const unmountEdit = () => dispatch => {
	dispatch({ type: 'operator.edit.unmountEdit' });
}
