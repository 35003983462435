import * as loginReducers from './login/login-reducers';
import * as firstStepReducers from './passwordRecover/firstStep/firstStep-reducers';
import * as secondStepReducers from './passwordRecover/secondStep/secondStep-reducers';
import * as topMenuReducers from './topMenu/topMenu-reducers';
import * as commonReducers from './common/common-reducers';
import * as finalUserCreateReducers from './user/finalUser/create/create-reducers';
import * as finalUserEditReducers from './user/finalUser/edit/edit-reducers';
import * as finalUserListReducers from './user/finalUser/list/list-reducers';
import * as finalUserDetailsReducers from './user/finalUser/details/details-reducers';
import * as unitCreateReducers from './user/unit/create/create-reducers';
import * as unitEditReducers from './user/unit/edit/edit-reducers';
import * as unitListReducers from './user/unit/list/list-reducers';
import * as unitDetailsReducers from './user/unit/details/details-reducers';
import * as processingCreateReducers from './user/processing/create/create-reducers';
import * as processingEditReducers from './user/processing/edit/edit-reducers';
import * as processingListReducers from './user/processing/list/list-reducers';
import * as processingDetailsReducers from './user/processing/details/details-reducers';
import * as mapCreateReducers from './user/map/create/create-reducers';
import * as mapEditReducers from './user/map/edit/edit-reducers';
import * as mapListReducers from './user/map/list/list-reducers';
import * as mapDetailsReducers from './user/map/details/details-reducers';
import * as operatorCreateReducers from './user/operator/create/create-reducers';
import * as operatorEditReducers from './user/operator/edit/edit-reducers';
import * as operatorListReducers from './user/operator/list/list-reducers';
import * as operatorDetailsReducers from './user/operator/details/details-reducers';

export const rootReducer = {
	...loginReducers,
	...firstStepReducers,
	...secondStepReducers,
	...topMenuReducers,
	...commonReducers,
	...finalUserCreateReducers,
	...finalUserEditReducers,
	...finalUserListReducers,
	...finalUserDetailsReducers,
	...unitCreateReducers,
	...unitEditReducers,
	...unitListReducers,
	...unitDetailsReducers,
	...processingCreateReducers,
	...processingEditReducers,
	...processingListReducers,
	...processingDetailsReducers,
	...mapCreateReducers,
	...mapEditReducers,
	...mapListReducers,
	...mapDetailsReducers,
	...operatorCreateReducers,
	...operatorEditReducers,
	...operatorListReducers,
	...operatorDetailsReducers
};
