import React from 'react';
import { Container } from 'semantic-ui-react';
import FirstStep from './FirstStep';
import { connect } from 'react-redux';
import { recover } from '../../../states/passwordRecover/firstStep/firstStep-actions';

function FirstStepContainer(props) {

	const params = {
		firstStep: props.firstStep,
		onSubmit: values => props.recover(values)
	}

	return (
		<Container fluid>
			<FirstStep {...params} />
		</Container>
	);
}

export default connect(
	state => ({
		firstStep: state.firstStep
	}),
	{ recover }
)(FirstStepContainer);
