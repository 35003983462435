const firstStepState = {
	message: '',
	error: ''
};

export function firstStep(state = firstStepState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'firstStep.recover': {
			return {
				...state,
				message: payload,
				error: ''
			};
		}

		case 'firstStep.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
