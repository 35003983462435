import React from 'react';
import { Route, Switch } from 'react-router';
import LoginContainer from '../components/Login/LoginContainer';
import PasswordRecoverFirstStepContainer from '../components/PasswordRecover/FirstStep/FirstStepContainer';
import PasswordRecoverSecondStepContainer from '../components/PasswordRecover/SecondStep/SecondStepContainer';
import UserHomeContainer from '../components/User/Home/HomeContainer';
import UserFinalUserCreateContainer from '../components/User/FinalUser/Create/CreateContainer';
import UserFinalUserEditContainer from '../components/User/FinalUser/Edit/EditContainer';
import UserFinalUserListContainer from '../components/User/FinalUser/List/ListContainer';
import UserFinalUserDetailsContainer from '../components/User/FinalUser/Details/DetailsContainer';
import UserUnitCreateContainer from '../components/User/Unit/Create/CreateContainer';
import UserUnitEditContainer from '../components/User/Unit/Edit/EditContainer';
import UserUnitListContainer from '../components/User/Unit/List/ListContainer';
import UserUnitDetailsContainer from '../components/User/Unit/Details/DetailsContainer';
import UserProcessingCreateContainer from '../components/User/Processing/Create/CreateContainer';
import UserProcessingEditContainer from '../components/User/Processing/Edit/EditContainer';
import UserProcessingListContainer from '../components/User/Processing/List/ListContainer';
import UserProcessingDetailsContainer from '../components/User/Processing/Details/DetailsContainer';
import UserMapCreateContainer from '../components/User/Map/Create/CreateContainer';
import UserMapEditContainer from '../components/User/Map/Edit/EditContainer';
import UserMapListContainer from '../components/User/Map/List/ListContainer';
import UserMapDetailsContainer from '../components/User/Map/Details/DetailsContainer';
import UserOperatorCreateContainer from '../components/User/Operator/Create/CreateContainer';
import UserOperatorEditContainer from '../components/User/Operator/Edit/EditContainer';
import UserOperatorListContainer from '../components/User/Operator/List/ListContainer';
import UserOperatorDetailsContainer from '../components/User/Operator/Details/DetailsContainer';

const Router = () => {

	return (
		<Switch>
			<Route exact path="/" component={props => <LoginContainer {...props} />} />
			<Route exact path="/passwordRecover/firstStep" component={props => <PasswordRecoverFirstStepContainer {...props} />} />
			<Route exact path="/passwordRecover/secondStep/:resetKey" component={props => <PasswordRecoverSecondStepContainer {...props} />} />
			<Route exact path="/user/home" component={props => <UserHomeContainer {...props} />} />
			<Route exact path="/user/azienda/crea" component={props => <UserFinalUserCreateContainer {...props} />} />
			<Route exact path="/user/azienda/modifica/:id" component={props => <UserFinalUserEditContainer {...props} />} />
			<Route exact path="/user/azienda/lista" component={props => <UserFinalUserListContainer {...props} />} />
			<Route exact path="/user/azienda/dettagli/:id" component={props => <UserFinalUserDetailsContainer {...props} />} />
			<Route exact path="/user/unitaProduttiva/crea" component={props => <UserUnitCreateContainer {...props} />} />
			<Route exact path="/user/unitaProduttiva/crea/:finalUserId" component={props => <UserUnitCreateContainer {...props} />} />
			<Route exact path="/user/unitaProduttiva/modifica/:id" component={props => <UserUnitEditContainer {...props} />} />
			<Route exact path="/user/unitaProduttiva/lista" component={props => <UserUnitListContainer {...props} />} />
			<Route exact path="/user/unitaProduttiva/dettagli/:id" component={props => <UserUnitDetailsContainer {...props} />} />
			<Route exact path="/user/lavorazione/crea" component={props => <UserProcessingCreateContainer {...props} />} />
			<Route exact path="/user/lavorazione/crea/:unitId" component={props => <UserProcessingCreateContainer {...props} />} />
			<Route exact path="/user/lavorazione/modifica/:id" component={props => <UserProcessingEditContainer {...props} />} />
			<Route exact path="/user/lavorazione/lista" component={props => <UserProcessingListContainer {...props} />} />
			<Route exact path="/user/lavorazione/dettagli/:id" component={props => <UserProcessingDetailsContainer {...props} />} />
			<Route exact path="/user/mappa/crea" component={props => <UserMapCreateContainer {...props} />} />
			<Route exact path="/user/mappa/crea/:unitId/:processingId" component={props => <UserMapCreateContainer {...props} />} />
			<Route exact path="/user/mappa/modifica/:id" component={props => <UserMapEditContainer {...props} />} />
			<Route exact path="/user/mappa/lista" component={props => <UserMapListContainer {...props} />} />
			<Route exact path="/user/mappa/dettagli/:id" component={props => <UserMapDetailsContainer {...props} />} />
			<Route exact path="/user/operatore/crea" component={props => <UserOperatorCreateContainer {...props} />} />
			<Route exact path="/user/operatore/crea/:unitId" component={props => <UserOperatorCreateContainer {...props} />} />
			<Route exact path="/user/operatore/modifica/:id" component={props => <UserOperatorEditContainer {...props} />} />
			<Route exact path="/user/operatore/lista" component={props => <UserOperatorListContainer {...props} />} />
			<Route exact path="/user/operatore/dettagli/:id" component={props => <UserOperatorDetailsContainer {...props} />} />
		</Switch>
	);
};

export default Router;
