const finalUserEditState = {
	data: {},
	finalUserEdited: false,
	isLoading: false,
	error: ''
};

export function finalUserEdit(state = finalUserEditState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'finalUser.edit.loadFinalUserData': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'finalUser.edit.editFinalUser': {
			return {
				...state,
				finalUserEdited: true
			};
		}

		case 'finalUser.edit.unmountEdit': {
			return {
				...state,
				finalUserEdited: false
			};
		}

		case 'finalUser.edit.setLoading': {
			return {
				...state,
				isLoading: true,
				data: {}
			};
		}

		case 'finalUser.edit.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
