const unitCreateState = {
	unitCreated: false,
	finalUserList: [],
	finalUserListLoaded: false,
	isLoading: false,
	error: '',
	finalUserListError: ''
};

export function unitCreate(state = unitCreateState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'unit.create.createUnit': {
			return {
				...state,
				unitCreated: true,
				isLoading: false
			};
		}

		case 'unit.create.getFinalUserList': {
			return {
				...state,
				finalUserList: payload,
				finalUserListLoaded: true
			};
		}

		case 'unit.create.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'unit.create.finalUserList.setLoading': {
			return {
				...state,
				finalUserListLoaded: false
			};
		}

		case 'unit.create.unmountCreate': {
			return {
				...state,
				unitCreated: false
			};
		}

		case 'unit.create.error': {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		}

		case 'unit.create.finalUserList.error': {
			return {
				...state,
				finalUserListError: payload
			};
		}

		default:
			return state;
	}
}
