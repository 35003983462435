import React from 'react';
import { Container, Form, Button, Dropdown, Modal, Icon, Dimmer, Loader, Message, Grid } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Field, reduxForm } from 'redux-form';
import './Edit.css';
var _ = require('lodash');

const required = value => value ? undefined : 'Required';
const inputField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
}

const fileTifField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input
				id='fileTif'
				type='file'
				accept='.tif'
				onChange={(e) => { field.input.onChange(e.target.files[0]) }}
			/>
		)
	else
		return (
			<Form.Input
				id='fileTif'
				type='file'
				accept='.tif'
				onChange={(e) => { field.input.onChange(e.target.files[0]) }}
			/>
		)
}

const fileSldField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input
				id='fileSld'
				type='file'
				accept='.sld'
				onChange={(e) => { field.input.onChange(e.target.files[0]) }}
			/>
		)
	else
		return (
			<Form.Input
				id='fileSld'
				type='file'
				accept='.sld'
				onChange={(e) => { field.input.onChange(e.target.files[0]) }}
			/>
		)
}

function Edit(props) {

	const unitDropdown = field => {
		var data = [];
		props.edit.unitList.forEach(element => {
			data.push({ key: element.id, text: element.name, value: element.id });
		});

		return (
			<Dropdown fluid search selection className='field'
				options={data}
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	}

	const processingDropdown = field => {
		var data = [];
		var isDisabled = true;
		var filteredUnit = _.find(props.edit.unitList, function (o) { return o.id == props.formUnitId });
		if (filteredUnit && filteredUnit.hasOwnProperty("processings")) {
			filteredUnit.processings.forEach(element => {
				data.push({ key: element.id, text: element.name, value: element.id });
			});
			isDisabled = false
		}

		if (field.input.value == "" && data.length > 0)
			props.change("processingId", data[0].key);

		return (
			<Dropdown fluid search selection className='field'
				options={data}
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
				disabled={isDisabled}
			/>
		)
	}

	const closeDatePicker = field => {
		return (
			<DateInput localization="it"
				fluid iconPosition="right" dateFormat='DD/MM/YYYY' closable
				onChange={(e, { value }) => { field.input.onChange(value) }}
				value={field.input.value}
			/>
		)
	}

	var mapListPath = '/user/mappa/lista/';

	const { handleSubmit, invalid, submitting, pristine } = props;

	return (
		<Container className='mapEdit'>
			<div className='title-box'>Modifica mappa</div>
			{(props.edit.isLoading || !props.edit.unitListLoaded) &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted className='strong'>Loading</Loader>
				</Dimmer>
			}
			<Form name='editMap' onSubmit={handleSubmit}>
				<label className='form-label'>Nome Mappa *</label>
				<Field name="name" component={inputField} validate={[required]} type="text" fluid />
				<label className='form-label'>Descrizione</label>
				<Field name="description" component={inputField} type="text" fluid />
				<label className='form-label'>Data di riferimento *</label>
				<Field name="refDate" component={closeDatePicker} type="text" fluid />
				<label className='form-label'>Unità produttiva *</label>
				<Field name="unitId" component={unitDropdown} validate={[required]} type="text" fluid />
				<label className='form-label'>Lavorazione *</label>
				<Field name="processingId" component={processingDropdown} validate={[required]} type="text" fluid />
				<label className='form-label'>Immagine della mappa (.tif) *</label>
				<Grid>
					<Grid.Row>
						<Grid.Column width={15} className="pr-0">
							<Field name="fileTif" component={fileTifField} validate={[required]} fluid />
						</Grid.Column>
						<Grid.Column width={1} className="pl-0" textAlign="right">
							<Button onClick={
								(event) => { props.change("fileTif", ""), document.getElementById('fileTif').value = null }
							} className="inlineButton" icon='delete' />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<label className='form-label'>File di stile della mappa (.sld) *</label>
				<Grid>
					<Grid.Row>
						<Grid.Column width={15} className="pr-0">
							<Field name="fileSld" component={fileSldField} validate={[required]} fluid />
						</Grid.Column>
						<Grid.Column width={1} className="pl-0" textAlign="right">
							<Button onClick={
								(event) => { props.change("fileSld", ""), document.getElementById('fileSld').value = null }
							} className="inlineButton" icon='delete' />
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Message
					error {...(props.edit.error != '' ? { visible: true } : { hidden: true })}>
					{props.edit.error}
				</Message>
				<Button type='submit' disabled={invalid || submitting || pristine}>Modifica</Button>
			</Form>
			<div className='p30'></div>
			<Modal size='tiny' open={props.edit.mapEdited}>
				<div className='modal-header'>Messaggio informativo</div>
				<div className='modal-content'>
					<p>Mappa modificata correttamente. <Icon name='check' /></p>
				</div>
				<div className='modal-footer'>
					<Button onClick={(event) => props.pushTo(event, mapListPath)}>OK</Button>
				</div>
			</Modal>
		</Container>
	);
}

Edit = reduxForm({
	form: 'editMap',
	enableReinitialize: true
})(Edit)

export default Edit
