import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Edit from './Edit';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form'
import { loadMapData, editMap, getUnitList, unmountEdit } from '../../../../states/user/map/edit/edit-actions';
import { pushTo } from '../../../../states/common/common-actions';
import Utils from '../../../../utils/Utils';

function EditContainer(props) {

	var cookieSession = Utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		edit: props.edit,
		cookieSession: cookieSession,
		onSubmit: values => props.editMap(props.match.params.id, values),
		pushTo: props.pushTo,
		initialValues: props.edit.data,
		formUnitId: props.formUnitId
	}

	useEffect(() => {
		props.getUnitList();
		props.loadMapData(props.match.params.id);
		return () => props.unmountEdit()
	}, []);

	return (
		<MainLayout {...props}>
			<Edit {...localProps} />
		</MainLayout>
	);
}

const selector = formValueSelector('editMap')
export default connect(
	state => ({
		edit: state.mapEdit,
		formUnitId: selector(state, 'unitId')
	}),
	{ loadMapData, editMap, getUnitList, unmountEdit, pushTo }
)(EditContainer);
