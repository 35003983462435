import React from 'react';
import { Container } from 'semantic-ui-react';
import SecondStep from './SecondStep';
import { connect } from 'react-redux';
import { completeRecover } from '../../../states/passwordRecover/secondStep/secondStep-actions';
import { pushTo } from '../../../states/common/common-actions';

function SecondStepContainer(props) {

	const params = {
		secondStep: props.secondStep,
		resetKey: props.match.params.resetKey,
		onSubmit: values => props.completeRecover(props.match.params.resetKey, values),
		pushTo: props.pushTo
	}

	return (
		<Container fluid>
			<SecondStep {...params} />
		</Container>
	);
}

export default connect(
	state => ({
		secondStep: state.secondStep
	}),
	{ completeRecover, pushTo }
)(SecondStepContainer);
