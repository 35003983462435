import React from 'react';
import { Menu, Image, Icon } from 'semantic-ui-react';
import FlexContainer from '../../Common/FlexContainer/FlexContainer';
import logo from '../../../images/vintes-logo.png';
import './TopMenu.css';

export default function TopMenu(props) {

	var toggleSidebar = function (event) {
		event.preventDefault();
		var sidebarElem = document.querySelector('#sidebar');
		if (window.getComputedStyle(sidebarElem).display === 'flex') {
			//document.styleSheets[3].rules[8].cssRules[0].style.display = 'none'
			sidebarElem.style.display = 'none';
			return;
		}
		sidebarElem.style.display = 'flex';
		//document.styleSheets[3].rules[8].cssRules[0].style.display = 'flex'
	};

	return (
		<FlexContainer className='row space-between topMenu'>
			<FlexContainer>
				<a href='' onClick={event => toggleSidebar(event)}>
					<div className='burger-menu m10'>
						<span className='icon-bar'></span>
						<span className='icon-bar'></span>
						<span className='icon-bar'></span>
					</div>
				</a>
			</FlexContainer>
			<FlexContainer>
				<div className='text-center title'>V.In.Te.S. Back-Office</div>
			</FlexContainer>
			<FlexContainer>
				<FlexContainer className='column center p10'>
					<a href='#' onClick={(event) => props.logout(event)}>
						<div className='text-center'><Icon name='sign-out' size='large' /></div>
						<div className='text-center'>Logout</div>
					</a>
				</FlexContainer>

			</FlexContainer>
		</FlexContainer>
	);
}

