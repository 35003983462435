import backendService from '../../../../services/BackendService';
import configApp from '../../../../config/config.app';

export const createOperator = (formData) => dispatch => {
	dispatch({ type: 'operator.create.setLoading' });
	formData.roles = [configApp.OPERATOR];
	formData.active = true;
	var filter = {
		"where": {
			"email": formData.email
		}
	}
	return backendService.getOperatorList(filter)
		.then(async res => {
			if (res.length == 0)
				backendService.createOperator(formData)
					.then(async res => {
						dispatch({ type: 'operator.create.createOperator' });
					})
					.catch(err => {
						dispatch({ type: 'operator.create.error', payload: err });
					});
			else {
				var message = 'Attenzione! email già presente nel sistema. Creazione non riuscita.';
				dispatch({ type: 'operator.create.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'operator.create.error', payload: err });
		});
}

export const unmountCreate = () => dispatch => {
	dispatch({ type: 'operator.create.unmountCreate' });
}
