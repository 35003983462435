import React from 'react';
import FlexContainer from '../../Common/FlexContainer/FlexContainer';
import {connect} from 'react-redux';
import TopMenu from '../TopMenu/TopMenu';
import Sidebar from '../Sidebar/Sidebar';
import {logout} from '../../../states/topMenu/topMenu-actions';
import {pushTo} from '../../../states/common/common-actions';
import './MainLayout.css';

function MainLayout(props) {
	return (
		<div className='mainLayout'>
			<TopMenu {...props}/>
			<FlexContainer className='stretch mainLayout-container'>
				<Sidebar {...props}/>
				{props.children}
			</FlexContainer>
		</div>
	)
}

export default connect(
    state => ({

	 }),
    {logout, pushTo}
)(MainLayout);