import React from 'react';
import { Container, Grid, Segment, Image } from 'semantic-ui-react';
import FlexContainer from '../../Common/FlexContainer/FlexContainer';
import finalUserImg from '../../../images/finalUser.png';
import unitImg from '../../../images/unit.png';
import processingImg from '../../../images/processing.png';
import mapImg from '../../../images/map.png';
import operatorImg from '../../../images/operator.png';
import utils from '../../../utils/Utils';
import './Home.css';

export default function Home(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);

	var finalUserListPath = '/user/azienda/lista';
	var unitListPath = '/user/unitaProduttiva/lista';
	var processingListPath = '/user/lavorazione/lista'
	var mapListPath = '/user/mappa/lista';
	var operatorListPath = '/user/operatore/lista';

	return (
		<Container className='userHome'>
			<div className='title-box'>Home Page</div>
			<Grid doubling stackable columns={/* cookieSession.isAdmin ? 4 :  */2}>

				<Grid.Column>
					<a href='' onClick={(event) => props.pushTo(event, finalUserListPath)}>
						<FlexContainer className='row center space-evenly link-box'>
							<Image src={finalUserImg} size='tiny' />
							<FlexContainer className='column center'>Aziende</FlexContainer>
						</FlexContainer>
					</a>
				</Grid.Column>


				<Grid.Column>
					<a href='' onClick={(event) => props.pushTo(event, unitListPath)}>
						<FlexContainer className='row center space-evenly link-box'>
							<Image src={unitImg} size='tiny' />
							<FlexContainer className='column center'>Unità produttive</FlexContainer>
						</FlexContainer>
					</a>
				</Grid.Column>

				<Grid.Column>
					<a href='' onClick={(event) => props.pushTo(event, processingListPath)}>
						<FlexContainer className='row center space-evenly link-box'>
							<Image src={processingImg} size='tiny' />
							<FlexContainer className='column center'>Lavorazioni</FlexContainer>
						</FlexContainer>
					</a>
				</Grid.Column>

				<Grid.Column>
					<a href='' onClick={(event) => props.pushTo(event, mapListPath)}>
						<FlexContainer className='row center space-evenly link-box'>
							<Image src={mapImg} size='tiny' />
							<FlexContainer className='column center'>Mappe</FlexContainer>
						</FlexContainer>
					</a>
				</Grid.Column>

				<Grid.Column>
					<a href='' onClick={(event) => props.pushTo(event, operatorListPath)}>
						<FlexContainer className='row center space-evenly link-box'>
							<Image src={operatorImg} size='tiny' />
							<FlexContainer className='column center'>Operatori</FlexContainer>
						</FlexContainer>
					</a>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
