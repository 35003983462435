import React from 'react';
import { Container } from 'semantic-ui-react';
import Login from './Login';
import { connect } from 'react-redux';
import { authenticate } from '../../states/login/login-actions';
import { pushTo } from '../../states/common/common-actions';

function LoginContainer(props) {

	const localProps = {
		login: props.login,
		pushTo: props.pushTo,
		onSubmit: values => props.authenticate(values)
	}

	return (
		<Container fluid>
			<Login {...localProps} />
		</Container>
	);
}

export default connect(
	state => ({
		login: state.login
	}),
	{ authenticate, pushTo }
)(LoginContainer);
