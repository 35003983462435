const operatorEditState = {
	data: {},
	operatorEdited: false,
	isLoading: false,
	error: ''
};

export function operatorEdit(state = operatorEditState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'operator.edit.loadOperatorData': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'operator.edit.editOperator': {
			return {
				...state,
				operatorEdited: true
			};
		}

		case 'operator.edit.unmountEdit': {
			return {
				...state,
				operatorEdited: false
			};
		}

		case 'operator.edit.setLoading': {
			return {
				...state,
				isLoading: true,
				data: {}
			};
		}

		case 'operator.edit.error': {
			return {
				...state,
				error: payload
			};
		}

		default:
			return state;
	}
}
