import React from 'react';
import { Container, Form, Button, Dropdown, Modal, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';
import { Field, reduxForm } from 'redux-form';
import './Create.css';

const required = value => value ? undefined : 'Required';
const inputField = field => {
	if ((field.meta.error && !field.meta.pristine))
		return (
			<Form.Input className='field' error
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	else
		return (
			<Form.Input className='field'
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
}

function Create(props) {

	const finalUserDropdown = field => {

		var data = [];

		props.create.finalUserList.forEach(element => {
			data.push({ key: element.id, text: element.name, value: element.id });
		});

		return (
			<Dropdown fluid search selection className='field'
				options={data}
				value={field.input.value}
				onChange={(e, { value }) => { field.input.onChange(value) }}
			/>
		)
	}

	var unitListPath = '/user/unitaProduttiva/lista/';

	const { handleSubmit, invalid, submitting, pristine } = props;

	return (
		<Container className='unitCreate'>
			<div className='title-box'>Crea unità produttiva</div>
			{(props.create.isLoading || !props.create.finalUserListLoaded) &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted className='strong'>Loading</Loader>
				</Dimmer>
			}
			<Form name='createUnit' onSubmit={handleSubmit}>
				<label className='form-label'>Nome Unità *</label>
				<Field name="name" component={inputField} validate={[required]} type="text" fluid />
				<label className='form-label'>Descrizione</label>
				<Field name="description" component={inputField} type="text" fluid />
				<label className='form-label'>Azienda *</label>
				<Field name="finalUserId" component={finalUserDropdown} validate={[required]} type="text" fluid />
				<Message
					error {...(props.create.error != '' ? { visible: true } : { hidden: true })}>
					{props.create.error}
				</Message>
				<Button type='submit' disabled={invalid || submitting || pristine}>Crea</Button>
			</Form>
			<div className='p30'></div>
			<Modal size='tiny' open={props.create.unitCreated}>
				<div className='modal-header'>Messaggio informativo</div>
				<div className='modal-content'>
					<p>Unità creata correttamente. <Icon name='check' /></p>
				</div>
				<div className='modal-footer'>
					<Button onClick={(event) => props.pushTo(event, unitListPath)}>OK</Button>
				</div>
			</Modal>
		</Container>
	);
}

Create = reduxForm({
	form: 'createUnit',
	enableReinitialize: true
})(Create)

export default Create
