import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import List from './List';
import { connect } from 'react-redux';
import { viewProcessingList, countProcessing, openDeletePopup, closeDeletePopup, deleteProcessing } from '../../../../states/user/processing/list/list-actions';
import { pushTo } from '../../../../states/common/common-actions';
import utils from '../../../../utils/Utils';
import configApp from '../../../../config/config.app';

function ListContainer(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		list: props.list,
		cookieSession: cookieSession,
		viewProcessingList: props.viewProcessingList,
		openDeletePopup: props.openDeletePopup,
		closeDeletePopup: props.closeDeletePopup,
		deleteProcessing: props.deleteProcessing,
		pushTo: props.pushTo
	};

	useEffect(() => {
		props.viewProcessingList(0, configApp.TABLE_ITEMS_PER_PAGE);
		props.countProcessing();
	}, []);


	return (
		<MainLayout {...props}>
			<List {...localProps} />
		</MainLayout>
	);

}

export default connect(
	state => ({
		list: state.processingList
	}),
	{ viewProcessingList, countProcessing, openDeletePopup, closeDeletePopup, deleteProcessing, pushTo }
)(ListContainer);
