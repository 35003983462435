import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Details from './Details';
import { connect } from 'react-redux';
import { pushTo } from '../../../../states/common/common-actions'
import { viewMapDetails, createMap } from '../../../../states/user/map/details/details-actions';
import utils from '../../../../utils/Utils';
const isBrowser = typeof window !== 'undefined';
const L = isBrowser ? require('leaflet') : undefined;
import config from '../../../../config/config.app';

function DetailsContainer(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);
	var mapContainerRef = React.createRef();

	var localProps = {
		isServer: props.staticContext ? true : false,
		details: props.details,
		cookieSession: cookieSession,
		pushTo: props.pushTo,
		id: props.match.params.id,
		mapContainerRef: mapContainerRef
	}

	useEffect(() => {
		props.viewMapDetails(props.match.params.id)

		var map = L.map('mapId').setView([0, 0], 18);
		L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
			attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
			maxZoom: 18,
			id: 'mapbox/satellite-v9',
			tileSize: 512,
			zoomOffset: -1,
			accessToken: config.MAPBOX_ACCESS_TOKEN
		}).addTo(map);
		props.createMap(map)
	}, []);

	return (
		<MainLayout {...props}>
			<Details {...localProps} />
		</MainLayout>
	);

}

export default connect(
	state => ({
		details: state.mapDetails
	}),
	{ viewMapDetails, createMap, pushTo }
)(DetailsContainer);
