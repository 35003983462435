import React from 'react';
import MainLayout from '../../Common/MainLayout/MainLayout';
import Home from './Home';
import {connect} from 'react-redux';
import {pushTo} from '../../../states/common/common-actions';

function HomeContainer(props) {

	return (
		<MainLayout>
			<Home {...props}/>
		</MainLayout>
	);
}

export default connect(
    state => ({
		
    }),
    {pushTo}
)(HomeContainer);
