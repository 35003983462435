const processingEditState = {
	data: {},
	processingEdited: false,
	unitList: [],
	unitListLoaded: false,
	isLoading: false,
	error: '',
	unitListError: ''
};

export function processingEdit(state = processingEditState, action) {
	let payload = action.payload;

	switch (action.type) {

		case 'processing.edit.loadData': {
			return {
				...state,
				data: payload,
				isLoading: false
			};
		}

		case 'processing.edit.editProcessing': {
			return {
				...state,
				processingEdited: true,
				isLoading: false
			};
		}

		case 'processing.edit.getUnitList': {
			return {
				...state,
				unitList: payload,
				unitListLoaded: true
			};
		}

		case 'processing.edit.setLoading': {
			return {
				...state,
				isLoading: true
			};
		}

		case 'processing.edit.unitList.setLoading': {
			return {
				...state,
				unitListLoaded: false
			};
		}

		case 'processing.edit.unmountEdit': {
			return {
				...state,
				processingEdited: false
			};
		}

		case 'processing.edit.error': {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		}

		case 'processing.edit.unitList.error': {
			return {
				...state,
				unitListError: payload
			};
		}

		default:
			return state;
	}
}
