import { push } from 'connected-react-router';
import backendService from '../../services/BackendService';

export const pushTo = (event, path) => dispatch => {
	event.preventDefault()
	return dispatch(push(path));
}

export const sendMail = (data) => dispatch => {
	return backendService.sendMail(data)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'common.mail.sendmail' });
			}
			else {
				dispatch({ type: 'common.mail.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'common.mail.error', payload: err });
		});
}
