import React from 'react';
import { Container, Table, Image, Button, Modal, Icon } from 'semantic-ui-react';
import details from '../../../../images/details.png';
import edit from '../../../../images/edit.png';
import edit_off from '../../../../images/edit_off.png';
import del from '../../../../images/delete.png';
import del_off from '../../../../images/delete_off.png';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';

export default function ProcessingList(props) {


	var unitProcessingRefCreatePath = '/user/lavorazione/crea/';
	var unitProcessingRefEditPath = '/user/lavorazione/modifica/';
	var unitProcessingRefDetailsPath = '/user/lavorazione/dettagli/';

	return (
		<div>
			<FlexContainer className='row space-between center sub-title'>
				<div>Lavorazioni</div>
				<div>
					<a href='' onClick={(event) => props.pushTo(event, unitProcessingRefCreatePath + props.id)} title="Crea una lavorazione dell'unità produttiva">
						<Container fluid className='link-button small'>
							&nbsp;Crea&nbsp;
						</Container>
					</a>
				</div>
			</FlexContainer>
			{props.details.data.hasOwnProperty("processings") &&
				<Table celled className='table small'>
					<Table.Header className='table-header'>
						<Table.Row>
							<Table.HeaderCell textAlign='center'>Nome</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Descrizione</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Azioni</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{[...props.details.data.processings].map((x, i) =>
							<Table.Row key={x.id}>
								<Table.Cell textAlign='center'>{x.name}</Table.Cell>
								<Table.Cell textAlign='center'>{x.description}</Table.Cell>
								<Table.Cell textAlign='center'>
									<FlexContainer className='row center'>
										<a href='' onClick={(event) => props.pushTo(event, unitProcessingRefDetailsPath + x.id)} title='Visualizza dettagli lavorazione'>
											<Container fluid className='link-box small'>
												<Image src={details} className='w25' />
											</Container>
										</a>
										<div className='p5'></div>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
											<a href='' onClick={(event) => props.pushTo(event, unitProcessingRefEditPath + x.id)} title='Modifica lavorazione'>
												<Container fluid className='link-box small'>
													<Image src={edit} className='w25' />
												</Container>
											</a>
										}
										{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
											<div fluid className='link-box small-off'>
												<Image src={edit_off} className='w25' />
											</div>
										}
										<div className='p5'></div>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) && x.isDeletable &&
											<a href='' onClick={(event) => props.openProcessingDeletePopup(event, x.id, x.name)} title='Elimina'>
												<Container fluid className='link-box small'>
													<Image src={del} className='w25' />
												</Container>
											</a>
										}
										{(!props.cookieSession.isAdmin && !props.cookieSession.isOperator) || !x.isDeletable &&
											<div fluid className='link-box small-off'>
												<Image src={del_off} className='w25' />
											</div>
										}
									</FlexContainer>
								</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>

					<Table.Footer>

					</Table.Footer>
				</Table>
			}
			{!props.details.data.hasOwnProperty("processings") &&
				<Container fluid className='info-box'>Non ci sono ancora lavorazioni collegate a questa unità produttiva</Container>
			}

			<Modal size='tiny' open={props.details.processingDeletePopup}>
				<div className='modal-header'>Messaggio di conferma</div>
				<div className='modal-content'>
					<p>Si è sicuri di voler eliminare la lavorazione <u>{props.details.processingDeletePopupName}</u>?</p>
				</div>
				<div className='modal-footer'>
					<Button onClick={props.closeProcessingDeletePopup}><Icon name='remove' />NO</Button>
					<Button onClick={() => props.deleteProcessing(props.details.processingDeletePopupId, props.details.data.id)}><Icon name='checkmark' />SI</Button>
				</div>
			</Modal>
		</div>
	);



}
