import backendService from '../../../../services/BackendService';
import config from '../../../../config/config.app';

export const countFinalUser = () => dispatch => {
	dispatch({ type: 'finalUser.list.setLoading' });
	return backendService.countFinalUser()
		.then(res => {
			dispatch({ type: 'finalUser.list.countFinalUser', payload: res.count });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.list.error', payload: err });
		});
}

export const viewFinalUserList = (startIndex, numItems) => dispatch => {
	dispatch({ type: 'finalUser.list.setLoading' });
	var filter = {
		"skip": startIndex,
		"limit": numItems
	}
	return backendService.getFinalUserList(filter)
		.then(async res => {

			for (var i = 0; i < res.length; i++) {
				var res2 = await backendService.isFinalUserDeletable(res[i].id)
				res[i].isDeletable = res2.isDeletable;
			}

			var payload = { index: startIndex, items: res }
			dispatch({ type: 'finalUser.list.viewFinalUserList', payload: payload });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.list.error', payload: err });
		});
}

export const openDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'finalUser.list.openDeletePopup', payload: { id: id, name: name } });
}

export const closeDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'finalUser.list.closeDeletePopup' });
}

export const deleteFinalUser = (id, index, count) => dispatch => {

	return backendService.deleteFinalUser(id)
		.then(res => {
			if (index == count) {
				index = index - config.TABLE_ITEMS_PER_PAGE
			}
			dispatch(countFinalUser());
			dispatch(viewFinalUserList(index, count));
			dispatch({ type: 'finalUser.list.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'finalUser.list.error', payload: err });
		});
}
