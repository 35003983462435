import React from 'react';
import { Container, Grid, Image, Dimmer, Loader } from 'semantic-ui-react';
import edit from '../../../../images/edit.png';
import './Details.css';
import UnitList from './UnitList';
import EmployeeList from './EmployeeList'

export default function Details(props) {

	var finalUserEditPath = '/user/azienda/modifica/';

	return (
		<Container className='finalUserDetails'>
			<div className='title-box  pb10'>Dettagli Azienda
			{props.cookieSession.isAdmin &&
					<a href='' className='float-right' onClick={(event) => props.pushTo(event, finalUserEditPath + props.details.data.id)} title='Modifica'>
						<div className='link-box small'>
							<Image src={edit} size='mini' />
						</div>
					</a>
				}
			</div>

			{(props.details.isLoading ||
				props.details.isUnitListLoading) &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			}

			{!props.details.isLoading &&
				!props.details.isUnitListLoading &&
				<div>
					<Grid columns={2} divided stackable>
						<Grid.Row>
							<Grid.Column>
								Email: <span className='fieldValue'>{props.details.data.email}</span>
							</Grid.Column>
							<Grid.Column>
								Cellulare: <span className='fieldValue'>{props.details.data.prefix + " " + props.details.data.mobile}</span>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<UnitList {...props} />

					<EmployeeList {...props} />
				</div>
			}
		</Container>
	);
}
