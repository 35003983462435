import backendService from '../../../../services/BackendService';

export const viewUnitDetails = (id) => dispatch => {
	dispatch({ type: 'unit.details.setLoading' });
	var filter = {
		"include": [
			{ "relation": "processings" },
			{
				"relation": "measures",
				"scope": {
					"include": [{ "relation": "parameter" }],
				}
			}
		]
	}
	return backendService.getUnit(id, filter)
		.then(async res => {
			if (!res.error) {

				if (res.hasOwnProperty("processings")) {
					for (var i = 0; i < res.processings.length; i++) {
						var res2 = await backendService.isProcessingDeletable(res.processings[i].id)
						res.processings[i].isDeletable = res2.isDeletable;
					}
				}

				dispatch({ type: 'unit.details.viewUnitDetails', payload: res });
			}
			else {
				dispatch({ type: 'unit.details.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'unit.details.error', payload: err });
		});
}

export const openProcessingDeletePopup = (event, id, name) => dispatch => {
	event.preventDefault();
	dispatch({ type: 'unit.details.processingList.openDeletePopup', payload: { id: id, name: name } });
}

export const closeProcessingDeletePopup = (id, name) => dispatch => {
	dispatch({ type: 'unit.details.processingList.closeDeletePopup' });
}

export const deleteProcessing = (id, unitId) => dispatch => {
	return backendService.deleteProcessing(id)
		.then(res => {
			dispatch(viewUnitDetails(unitId));
			dispatch({ type: 'unit.details.processingList.closeDeletePopup' });
		})
		.catch(err => {
			dispatch({ type: 'unit.details.processingList.error', payload: err });
		});
}





