import backendService from '../../services/BackendService';
import { push } from 'connected-react-router';

export const logout = (event) => dispatch => {
	event.preventDefault();
	return backendService.logout()
		.then(res => {
			if (!res.error) {
				if (res.response.isLoggedOut) {
					dispatch(push('/'));
				}
			}
			else {
				dispatch({ type: 'topMenu.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'topMenu.error', payload: err });
		});
}
