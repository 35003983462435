import React from 'react';
import { Container, Grid, Image, Dimmer, Loader } from 'semantic-ui-react';
import edit from '../../../../images/edit.png';
import './Details.css';
const isBrowser = typeof window !== 'undefined';
const L = isBrowser ? require('leaflet') : undefined;

export default function Details(props) {

	var mapEditPath = '/user/mappa/modifica/';
	var unitDetailsPath = '/user/unitaProduttiva/dettagli/';
	var processingDetailsPath = '/user/lavorazione/dettagli/';
	var finalUserDetailsPath = '/user/azienda/dettagli/';

	const mapRender = () => {
		if (!props.isServer && !props.details.isLoading) {
			var mapBounds = props.details.data.imgRasterMeta;
			var mapCenterLat = (mapBounds[0][0] + mapBounds[1][0] + mapBounds[2][0] + mapBounds[3][0]) / 4
			var mapCenterLng = (mapBounds[0][1] + mapBounds[1][1] + mapBounds[2][1] + mapBounds[3][1]) / 4
			props.details.map.panTo([mapCenterLat, mapCenterLng]);
			var imageUrl = 'data:image/jpeg;base64,' + props.details.data.imgRaster
			L.imageOverlay(imageUrl, props.details.data.imgRasterMeta).addTo(props.details.map);
			props.details.map.fitBounds(props.details.data.imgRasterMeta);

			var legend = L.control({ position: 'bottomleft' });
			legend.onAdd = function (map) {

				var div = L.DomUtil.create('div', 'info legend');
				var legendaUrl = 'data:image/jpeg;base64,' + props.details.data.imgRasterLegenda
				div.innerHTML = "<img src='" + legendaUrl + "' width='150'/>";
				return div;
			};
			legend.addTo(props.details.map);
		}
	}

	return (
		<Container className='mapDetails'>
			<div className='title-box  pb10'>Dettagli mappa
			{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
					<a href='' className='float-right' onClick={(event) => props.pushTo(event, mapEditPath + props.details.data.id)} title='Modifica'>
						<div className='link-box small'>
							<Image src={edit} size='mini' />
						</div>
					</a>
				}
			</div>

			{(props.details.isLoading) &&
				<Dimmer active inverted page={props.isServer ? false : true}>
					<Loader inverted>Loading</Loader>
				</Dimmer>
			}

			{!props.details.isLoading &&
				<div>
					<Grid columns={2} divided stackable>
						<Grid.Row>
							<Grid.Column>
								Nome: <span className='fieldValue'>{props.details.data.name}</span>
							</Grid.Column>
							<Grid.Column>
								Descrizione: <span className='fieldValue'>{props.details.data.description}</span>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								Azienda: <a href={finalUserDetailsPath + props.details.data.processing.unit.finalUser.id} onClick={(event) => props.pushTo(event, finalUserDetailsPath + props.details.data.processing.unit.finalUser.id)} className='text-underline'>
									{props.details.data.processing.unit.finalUser.name}</a>
							</Grid.Column>
							<Grid.Column>
								Unità produttiva: <a href={unitDetailsPath + props.details.data.processing.unit.id} onClick={(event) => props.pushTo(event, unitDetailsPath + props.details.data.processing.unit.id)} className='text-underline'>
									{props.details.data.processing.unit.name}</a>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								Lavorazione: <a href={processingDetailsPath + props.details.data.processing.id} onClick={(event) => props.pushTo(event, processingDetailsPath + props.details.data.processing.id)} className='text-underline'>
									{props.details.data.processing.name}</a>
							</Grid.Column>
							<Grid.Column>
								Data di riferimento: <span className='fieldValue'>{(new Date(props.details.data.refDate)).toLocaleString("it-IT", { day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			}

			{mapRender()}
			<div id="mapId" className="mapContainer"></div>

		</Container>
	);
}
