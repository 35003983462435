import React from 'react';
import { Container, Table, Image, Button, Modal, Icon } from 'semantic-ui-react';
import del from '../../../../images/delete.png';
import FlexContainer from '../../../Common/FlexContainer/FlexContainer';

export default function EmployeeList(props) {

	return (
		<div>
			<FlexContainer className='row space-between center sub-title'>
				<div>Elenco dipendenti</div>
			</FlexContainer>
			{props.details.data.hasOwnProperty("employees") &&
				<Table celled className='table small'>
					<Table.Header className='table-header'>
						<Table.Row>
							<Table.HeaderCell textAlign='center'>Cellulare</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'>Azioni</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{[...props.details.data.employees].map((x, i) =>
							<Table.Row key={x.id}>
								<Table.Cell textAlign='center'>{x.prefix}{x.mobile}</Table.Cell>
								<Table.Cell textAlign='center'>
									<FlexContainer className='row center'>
										{(props.cookieSession.isAdmin || props.cookieSession.isOperator) &&
											<a href='' onClick={(event) => props.openEmployeeDeletePopup(event, x.id, x.name)} title='Elimina'>
												<div className='link-box small'>
													<Image src={del} className='w25' />
												</div>
											</a>
										}
										{!props.cookieSession.isAdmin && !props.cookieSession.isOperator &&
											<div fluid className='link-box small-off'>
												<Image src={del_off} className='w25' />
											</div>
										}
									</FlexContainer>
								</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>

					<Table.Footer>

					</Table.Footer>
				</Table>
			}
			{!props.details.data.hasOwnProperty("employees") &&
				<Container fluid className='info-box'>Non ci sono ancora dipendenti collegati a questa azienda</Container>
			}
			<div className='p30'></div>
			<Modal size='tiny' open={props.details.employeeDeletePopup}>
				<div className='modal-header'>Messaggio di conferma</div>
				<div className='modal-content'>
					<p>Si è sicuri di voler eliminare il dipendente <u>{props.details.employeeDeletePopupName}</u>?</p>
				</div>
				<div className='modal-footer'>
					<Button onClick={props.closeEmployeeDeletePopup}><Icon name='remove' />NO</Button>
					<Button onClick={() => props.deleteEmployee(props.details.employeeDeletePopupId, props.details.data.id)}><Icon name='checkmark' />SI</Button>
				</div>
			</Modal>
		</div>
	);



}
