import backendService from '../../../services/BackendService';

export const recover = (userDataObj) => dispatch => {
	return backendService.recover(userDataObj)
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'firstStep.recover', payload: res });
			}
			else {
				dispatch({ type: 'firstStep.error', payload: res.error.message });
			}
		})
	/*.catch(err => {
		dispatch({ type: 'firstStep.error', payload: err.message });
	});*/
}
