import React, { useEffect } from 'react';
import MainLayout from '../../../Common/MainLayout/MainLayout';
import Details from './Details';
import { connect } from 'react-redux';
import { pushTo } from '../../../../states/common/common-actions'
import { viewProcessingDetails, openMapDeletePopup, closeMapDeletePopup, deleteMap } from '../../../../states/user/processing/details/details-actions';
import utils from '../../../../utils/Utils';

function DetailsContainer(props) {

	var cookieSession = utils.getCookieSession(props.staticContext);

	var localProps = {
		isServer: props.staticContext ? true : false,
		details: props.details,
		cookieSession: cookieSession,
		openMapDeletePopup: props.openMapDeletePopup,
		closeMapDeletePopup: props.closeMapDeletePopup,
		deleteMap: props.deleteMap,
		pushTo: props.pushTo,
		id: props.match.params.id
	}

	useEffect(() => {
		props.viewProcessingDetails(props.match.params.id)
	}, []);

	return (
		<MainLayout {...props}>
			<Details {...localProps} />
		</MainLayout>
	);

}

export default connect(
	state => ({
		details: state.processingDetails
	}),
	{ viewProcessingDetails, openMapDeletePopup, closeMapDeletePopup, deleteMap, pushTo }
)(DetailsContainer);
