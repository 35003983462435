import backendService from '../../../../services/BackendService';
import configApp from '../../../../config/config.app';

export const createUnit = (formData) => dispatch => {
	dispatch({ type: 'unit.create.setLoading' });
	var filter = {
		"where": {
			"name": formData.name,
			"finalUserId": formData.finalUserId
		}
	}
	return backendService.getUnitList(filter)
		.then(async res => {
			if (res.length == 0)
				backendService.createUnit(formData)
					.then(async res => {
						dispatch({ type: 'unit.create.createUnit' });
					})
					.catch(err => {
						dispatch({ type: 'unit.create.error', payload: err });
					});
			else {
				var message = 'Attenzione! Nome già presente nel sistema per l\'azienda. Creazione non riuscita.';
				dispatch({ type: 'unit.create.error', payload: message });
			}
		})
		.catch(err => {
			dispatch({ type: 'unit.create.error', payload: err });
		});
}

export const getFinalUserList = () => dispatch => {
	dispatch({ type: 'unit.create.finalUserList.setLoading' });
	return backendService.getFinalUserList({})
		.then(res => {
			if (!res.error) {
				dispatch({ type: 'unit.create.getFinalUserList', payload: res });
			}
			else {
				dispatch({ type: 'unit.create.finalUserList.error', payload: res.error });
			}
		})
		.catch(err => {
			dispatch({ type: 'unit.create.finalUserList.error', payload: err });
		});
}

export const unmountCreate = () => dispatch => {
	dispatch({ type: 'unit.create.unmountCreate' });
}
